import * as React from 'react';
import { MarkdownBlock } from '../common/MarkdownBlock';
import { Icon } from '../common/Icon';
import { A, P, Section, Container, Button, Div, Span } from '../common/Elements';
import { useContentfulPsaBanner } from '../../common/hooks/useContentfulPsaBanner';
import { psaBannerInterface } from '../../contentful/content-types/psa-banner';

export const ContentfulPsaBanner: React.FC<{ banner: psaBannerInterface }> = ({ banner }) => {
  const [psaBanner, closeBanner] = useContentfulPsaBanner(banner);
  return (
    <>
      {psaBanner && !psaBanner.isPsaBannerClosed && (
        <Section
          backgroundColor="blue0"
          color="white"
          display="flex"
          justifyContent="around"
          className="promo-banner"
        >
          <Container
            display="flex"
            justifyContent="center"
            margin={0}
            paddingHorizontal={[2, 0, 0, 0]}
            position="relative"
            alignItems="center"
          >
            <Div display={'flex'} flexDirection={['column', 'row']} marginRight={[0, 0, 0, 11]}>
              <MarkdownBlock
                display="flex"
                flex="auto"
                source={psaBanner.text}
                renderers={{
                  paragraph: ({ children }) => (
                    <Div
                      display="flex"
                      color="white"
                      alignItems="start"
                      justifyContent="center"
                      position="relative"
                      flexDirection={['column', 'row']}
                      fontSize={2}
                      paddingVertical={1}
                    >
                      <Div>
                        <Span marginRight={1}>
                          {children}

                          <A
                            href={banner?.learnMoreUrl}
                            display={['inline', 'none']}
                            textAlign="left"
                            alignItems="center"
                            hovered={{ color: 'blue5' }}
                            color="white"
                            fontWeight={6}
                            fontSize={2}
                          >
                            {banner?.learnMoreText} <Icon className="ml1" type="chevron" />
                          </A>
                        </Span>{' '}
                        <A
                          href={banner?.learnMoreUrl}
                          display={['none', 'inline-flex']}
                          textAlign="left"
                          alignItems="center"
                          hovered={{ color: 'blue5' }}
                          color="white"
                          fontWeight={6}
                          fontSize={2}
                        >
                          {banner?.learnMoreText} <Icon className="ml1" type="chevron" />
                        </A>
                      </Div>
                    </Div>
                  ),
                }}
              />
            </Div>

            <Button
              paddingLeft={2}
              lineHeight={1}
              onClick={closeBanner}
              position="absolute"
              style={{ right: 0 }}
            >
              <Icon type="x" fill="white" width={2} height={2} />
            </Button>
          </Container>
        </Section>
      )}
    </>
  );
};
