import React, { FC, useState } from 'react';
import { DropdownState, navDefaultColumns } from '..';

export type DropdownStateProvider = {
  dropdownState: boolean[];
  toggleDropdownStateIndex: (index: number) => void;
  setDropdownState: (state: boolean[]) => void;
  clearState: () => void;
};

const defaultState = new Array(navDefaultColumns).fill(false) as DropdownState;

const DropdownStateContext = React.createContext<Partial<DropdownStateProvider>>({});

export const DropdownStateProvider = DropdownStateContext.Provider;
export const DropdownStateConsumer = DropdownStateContext.Consumer;

const DropdownContextProvider: FC = ({ children }) => {
  const [dropdownState, setDropdownState] = useState<boolean[]>(defaultState);

  const toggleDropdownStateIndex = (index: number): void => {
    const newArray = new Array(dropdownState.length).fill(false);
    newArray[index] = !dropdownState[index];
    setDropdownState(newArray);
  };

  const clearState = () => setDropdownState(defaultState);

  return (
    <DropdownStateProvider
      value={{ dropdownState, setDropdownState, toggleDropdownStateIndex, clearState }}
    >
      {children}
    </DropdownStateProvider>
  );
};

export default DropdownContextProvider;
