import React, { FC, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Experiment, Variant } from 'react-optimize';
import Modal from 'react-modal';
import RefreshedFooter from './refreshed-footer';
import { Header as RefreshedHeader } from './refreshed-header';
import Header from './header/header';
import DropdownContextProvider, {
  DropdownStateConsumer,
} from './header/context/dropdown-state.context';
import AppContextProvider from '../common/context/app.context';
import { TopNav } from './header';
import { AppContextProps } from '../../common/interfaces/locale-props.interface';
import { HrefLangs } from './header/HrefLangs';
import { PsaBanner } from './PsaBanner';
import { makeStyledComponent, Div } from '../common/Elements/index';
import { MainFooter } from './footer/footer.interfaces';
import { MaybeRedwood } from '../../config';
import { useLocale } from '../../common/hooks/useLocale';
import { ContentfulNavigation } from '../../contentful/content-types/navigation';
import { ContentfulPsaBanner } from './ContentfulPsaBanner';
import { psaBannerInterface } from '../../contentful/content-types/psa-banner';
import { TargetEnv } from '../../common/util/TargetEnv';

Modal.setAppElement('#___gatsby');

const Container = makeStyledComponent(({ children }) => (
  <Div display="flex" flexDirection="column" width="100%">
    {children}
  </Div>
));

interface SiteMetadata {
  coreApiV1: string;
  title: string;
  targetEnv?: TargetEnv;
}

interface ComponentProps {
  site: {
    siteMetadata: SiteMetadata;
  };
}

const metaDataQuery = graphql`
  query {
    site {
      siteMetadata {
        targetEnv
        coreApiV1
      }
    }
  }
`;

export interface LayoutProps {
  pageContext: AppContextProps;
  topNavData: TopNav;
  topNavAbTestData?: TopNav;
  hrefLangs?: ReturnType<typeof HrefLangs>;
  footerData: MainFooter;
  refreshStyles?: boolean;
  redwoodNavData: ContentfulNavigation;
  redwoodRightNavData: ContentfulNavigation;
  utilityNavData: ContentfulNavigation;
  psaBanner?: psaBannerInterface;
  customHeaderLogoUrl?: string | undefined;
}

const Layout: FC<LayoutProps> = ({
  children,
  pageContext,
  topNavData,
  footerData,
  topNavAbTestData,
  utilityNavData,
  redwoodNavData,
  redwoodRightNavData,
  psaBanner,
  customHeaderLogoUrl,
  refreshStyles,
  hrefLangs = HrefLangs(pageContext.locale, pageContext.pathname, pageContext.baseURL),
}) => {
  const [countryCode, setCountryCode] = React.useState('US');
  const { site }: ComponentProps = useStaticQuery(metaDataQuery);
  const locale = useLocale();

  useEffect(() => {
    const redwood = (window as MaybeRedwood).redwood;
    setCountryCode(redwood?.country || countryCode);
  }, []);
  const OTsettings: { [K in Exclude<SiteMetadata['targetEnv'], undefined>]: JSX.Element | null } = {
    production: (
      <script
        async
        src="https://www.cloudflare.com/vendor/onetrust/scripttemplates/otSDKStub.js"
        data-document-language="true"
        type="text/javascript"
        data-domain-script="e34df59b-4a48-4bf9-b2b5-7a4bb09cd231"
      ></script>
    ),
    staging: (
      <script
        async
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        data-document-language="true"
        type="text/javascript"
        data-domain-script="e34df59b-4a48-4bf9-b2b5-7a4bb09cd231-test"
      ></script>
    ),
    development: (
      <script
        async
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        data-document-language="true"
        type="text/javascript"
        data-domain-script="e34df59b-4a48-4bf9-b2b5-7a4bb09cd231-test"
      ></script>
    ),
    preview: (
      <script
        async
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        data-document-language="true"
        type="text/javascript"
        data-domain-script="e34df59b-4a48-4bf9-b2b5-7a4bb09cd231-test"
      ></script>
    ),
    test: null,
  };

  return (
    <AppContextProvider pageContext={pageContext}>
      <Container>
        <Helmet>
          <title>Cloudflare - The Web Performance &amp; Security Company | Cloudflare</title>
          <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
          <meta
            name="description"
            content="Here at Cloudflare, we make the Internet work the way it should. Offering CDN, DNS, DDoS protection and security, find out how we can help your site."
          ></meta>
          <meta
            name="title"
            content="Cloudflare - The Web Performance &amp; Security Company | Cloudflare"
          ></meta>
          <meta name="twitter:creator" content="@cloudflare" />
          <meta name="twitter:site" content="@cloudflare"></meta>
          <meta property="og:site_name" content="Cloudflare"></meta>
          {hrefLangs}
          {OTsettings[site.siteMetadata.targetEnv || 'development']}
          {site.siteMetadata.targetEnv === 'development' && (
            <script async src="https://www.googleoptimize.com/optimize.js?id=GTM-N4JSZJ8"></script>
          )}
          <html lang={useLocale().toLowerCase()} />
        </Helmet>
        <PsaBanner />
        {psaBanner && <ContentfulPsaBanner banner={psaBanner} />}
        <DropdownContextProvider>
          {/* If topNavAbTestData is defined, then we want to setup an a/b test, if not render the normal nav */}
          <>
            <Experiment
              id="ZDsvRQIJSjaHQ6FLZNlCgg"
              loader={
                <Header
                  locale={pageContext.locale}
                  topNavData={topNavData}
                  navColumns={7}
                  customHeaderLogoUrl={customHeaderLogoUrl}
                  countryCode={countryCode}
                />
              }
            >
              <Variant id={site.siteMetadata.targetEnv === 'production' ? '0' : '1'}>
                <Header
                  locale={pageContext.locale}
                  topNavData={topNavData}
                  navColumns={7}
                  customHeaderLogoUrl={customHeaderLogoUrl}
                  countryCode={countryCode}
                />
              </Variant>
              <Variant id={site.siteMetadata.targetEnv === 'production' ? '1' : '0'}>
                <RefreshedHeader
                  redwoodRightNavData={redwoodRightNavData}
                  utilityNav={utilityNavData}
                  navData={redwoodNavData}
                  customHeaderLogoUrl={customHeaderLogoUrl}
                />
              </Variant>
            </Experiment>
          </>

          <DropdownStateConsumer>
            {({ clearState }) => (
              <div onClick={e => clearState && clearState()} className="site-content">
                {children}
              </div>
            )}
          </DropdownStateConsumer>
          <RefreshedFooter footerData={footerData} />
          {/* <Footer mainFooter={footerData} refreshStyles={refreshStyles} /> */}
        </DropdownContextProvider>
      </Container>
    </AppContextProvider>
  );
};

export default Layout;
