import React from 'react';
import { ContentfulNavigationLink } from '../../../../../contentful/content-types/navigationlink';
import { Div, Span } from '../../../../common/Elements';
import { Icon } from '../../../../common/Icon';
import { LocaleLink } from '../../../../common/links/LocaleLink';
import Text from '../../../../common/Text/Text';
import { useHeaderContext } from '../../header.context';
import { trackNavigationData } from '../../utils/navigation.tracking';

interface Props {
  merchandize: ContentfulNavigationLink;
}

export const Merchandize: React.FC<Props> = ({ merchandize }) => {
  const { modal } = useHeaderContext();
  return (
    <Div
      paddingHorizontal={3}
      marginBottom={2}
      paddingVertical={2}
      border="all"
      borderColor="gray2"
      flexDirection="column"
      display="flex"
      style={{
        height: '233px',
      }}
    >
      <Div marginBottom={3}>
        {merchandize.badges?.map(tag => (
          <Text
            tag="Span"
            key={tag}
            display="inline-flex"
            border="all"
            borderColor="black"
            paddingHorizontal={1}
            paddingVertical={0}
            variant="body3-bold"
          >
            {tag}
          </Text>
        ))}
      </Div>
      <Text flex="auto" variant="body3-bold">
        {merchandize.description}
      </Text>

      <LocaleLink
        display="block"
        openInNewTab={!!merchandize.openInNewWindow}
        variant={merchandize.isExternalUrl ? 'anchor' : 'link'}
        className="newNav-link"
        to={merchandize.url}
        onClick={() =>
          trackNavigationData({
            element: 'link',
            label: `mainnav|${modal?.modalProps?.modalData?.name}_merchandize-${merchandize.title}:[${merchandize.url}]`,
          })
        }
      >
        <Text display="flex" tag="Span" alignItems="center" variant="body2-bold" color="blue1">
          {merchandize.title}
          <Span lineHeight={0} marginLeft="4px">
            <Icon type="link-caret" />
          </Span>
        </Text>
      </LocaleLink>
    </Div>
  );
};
