import React from 'react';
import { initialModalState, ModalAction, ModalState } from './reducers/modal.reducer';

export interface ModalContext {
  modal: ModalState;
  dispatch: React.Dispatch<ModalAction>;
}

export const HeaderContext = React.createContext<ModalContext>({
  modal: initialModalState,
  dispatch: () => {},
});
export const useHeaderContext = (): ModalContext => React.useContext(HeaderContext);
