import React from 'react';
import { IconProps } from './icon.interface';

export const BaseIcon: React.FC<Pick<IconProps, 'size' | 'className'>> = ({
  size,
  className,
  children,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 64 64"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    {children}
  </svg>
);
