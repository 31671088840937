import React from 'react';
import { Div } from '../../common/Elements';
import { TopNav } from './components/Topnav';
import { MainNav } from './components/Mainnav';
import { ContentfulNavigation } from '../../../contentful/content-types/navigation';

interface HeaderProps {
  modalNav?: boolean;
  navData: ContentfulNavigation;
  rightNavData: ContentfulNavigation;
  utilityNav: ContentfulNavigation;
  customHeaderLogoUrl?: string | undefined;
}

export const MainHeader: React.FC<HeaderProps> = ({
  modalNav,
  navData,
  rightNavData,
  utilityNav,
  customHeaderLogoUrl,
}) => {
  return (
    <Div
      paddingBottom={[0, 0, 0, 1]}
      position="sticky"
      zIndex="max"
      color="white"
      className="top-0"
      backgroundColor={modalNav ? 'blue4' : 'white'}
    >
      <Div
        width="100%"
        marginVertical={0}
        paddingHorizontal={[3, 3, 3, 6]}
        style={{
          maxWidth: '1440px',
          margin: '0 auto',
        }}
      >
        <Div
          display={['none', 'none', 'none', 'block']}
          paddingTop={1}
          lineHeight={0}
          paddingBottom={1}
        >
          <TopNav utilityNav={utilityNav} />
        </Div>
        <Div paddingVertical={[2, 2, 2, 1]}>
          <MainNav
            utilityNav={utilityNav}
            rightNavData={rightNavData}
            navData={navData}
            modalNav={modalNav}
            customHeaderLogoUrl={customHeaderLogoUrl}
          />
        </Div>
      </Div>
    </Div>
  );
};
