import React from 'react';
import { makeStyledComponent } from './Elements/index';
import classNames from 'classnames';

export const Hamburger = makeStyledComponent<{
  isActive: boolean;
  onClick: React.HTMLAttributes<HTMLDivElement>['onClick'];
}>(({ className, isActive, onClick }) => (
  <div
    className={classNames('hamburger hamburger--spring', className, {
      'is-active': isActive,
    })}
    onClick={onClick}
  >
    <div className="hamburger-box">
      <div className="hamburger-inner"></div>
    </div>
  </div>
));
