import React from 'react';
import { ContentfulNavigationItem } from '../../../../../contentful/content-types/navigationitem';
import { navigationlink } from '../../../../../contentful/content-types/navigationlink/navigationlink.interface';
import { Div } from '../../../../common/Elements';
import { LocaleLink } from '../../../../common/links/LocaleLink';
import Text from '../../../../common/Text/Text';
import { useHeaderContext } from '../../header.context';
import { trackNavigationData } from '../../utils/navigation.tracking';
import { MenuTabItem } from './MenuTabItem';

interface Props {}

export const MenuTabList: React.FC<Props> = () => {
  const { modal } = useHeaderContext();
  const tabs = modal.modalProps.modalData?.navigationItems;
  const tabLinks = modal.modalProps.modalData?.navigationLinks?.filter(
    link => !link.specialLinkType
  );
  return (
    <Div>
      {tabs?.map((tab: ContentfulNavigationItem, index: number) => (
        <MenuTabItem lastItem={index === tabs.length - 1} tab={tab} key={tab.contentfulId} />
      ))}
      {tabLinks?.map((link: navigationlink, index: number) => (
        <Div
          display="flex"
          justifyContent="between"
          border={index === tabLinks.length - 1 ? 'none' : 'bottom'}
          borderColor="gray2"
          paddingVertical={2}
          className="pointer"
        >
          <LocaleLink
            openInNewTab={!!link.openInNewWindow}
            width="100%"
            className="newNav-link"
            display="block"
            variant="anchor"
            to={link?.url || ''}
            onClick={() =>
              trackNavigationData({
                element: 'button',
                label: `mainnav|${modal?.modalProps?.modalData?.name}|mainnav-sidebar_${link.title}:[${link.url}]`,
              })
            }
          >
            <Text color="black" tag="Span" variant="body2-bold">
              {link.title}
            </Text>
          </LocaleLink>
        </Div>
      ))}
    </Div>
  );
};
