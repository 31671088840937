import React from 'react';
import { BaseIcon } from './BaseIcon';
import { colorMap, IconProps } from './icon.interface';

export const CFArrowBackward: React.FC<IconProps> = ({ size, color, className }) => (
  <BaseIcon className={className} size={size}>
    <path
      d="M56 30L13.755 29.999L30.918 12.453L28.059 9.65601L6.20203 32L28.059 54.344L30.918 51.547L13.753 33.999L56 34V30Z"
      fill={colorMap[color]}
    />
  </BaseIcon>
);
