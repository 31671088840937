import React from 'react';
import { BaseIcon } from './BaseIcon';
import { colorMap, IconProps } from './icon.interface';

export const CFInternetGlobe: React.FC<IconProps> = ({ size, color, className }) => (
  <BaseIcon className={className} size={size}>
    <path
      d="M32.8499 5.99997H32.2099C28.7956 5.98618 25.4119 6.64503 22.2522 7.93892C19.0924 9.2328 16.2185 11.1364 13.7944 13.5409C11.3703 15.9455 9.44362 18.804 8.12426 21.9532C6.8049 25.1024 6.11874 28.4806 6.10495 31.895C6.09116 35.3093 6.75002 38.693 8.0439 41.8527C9.33778 45.0125 11.2414 47.8864 13.6459 50.3105C16.0505 52.7346 18.909 54.6613 22.0582 55.9807C25.2074 57.3 28.5856 57.9862 31.9999 58H32.8399C39.7356 58 46.3488 55.2607 51.2247 50.3847C56.1007 45.5088 58.84 38.8956 58.84 32C58.84 25.1043 56.1007 18.4911 51.2247 13.6152C46.3488 8.73925 39.7356 5.99997 32.8399 5.99997H32.8499ZM22.0999 12.36C20.0507 15.4993 18.6122 18.9972 17.8599 22.67H12.0899C14.1869 18.2155 17.7093 14.5875 22.0999 12.36ZM9.99995 32C10.001 30.2036 10.2193 28.414 10.6499 26.67H17.1699C16.9672 28.4394 16.867 30.219 16.8699 32C16.8655 33.6704 16.9557 35.3397 17.1399 37H10.5699C10.1929 35.36 10.0017 33.6827 9.99995 32ZM11.8999 41H17.7699C18.5171 44.7916 19.9872 48.404 22.0999 51.64C17.5926 49.3547 13.9985 45.6018 11.9099 41H11.8999ZM29.9999 53.52C26.4199 52 23.4099 47.23 21.8999 41H29.9999V53.52ZM29.9999 37H21.1799C20.7506 33.5856 20.7674 30.13 21.2299 26.72H29.9999V37ZM29.9999 22.72H21.9999C23.5399 16.63 26.5099 11.99 29.9999 10.53V22.72ZM51.9399 22.72H46.82C46.1005 19.2668 44.7877 15.9646 42.9399 12.96C46.8581 15.2054 49.9979 18.5929 51.9399 22.67V22.72ZM33.9999 10.24C37.7999 11.35 41 16.24 42.6899 22.67H33.9999V10.24ZM33.9999 26.67H43.45C43.688 28.4367 43.8083 30.2173 43.81 32C43.8112 33.6715 43.7077 35.3414 43.5 37H33.9999V26.67ZM33.9999 53.76V41H42.7799C41.18 47.62 37.8699 52.63 33.9999 53.76ZM42.9399 51.09C44.8497 47.989 46.1907 44.5721 46.9 41H52.11C50.1891 45.2431 46.9806 48.7734 42.9399 51.09ZM47.54 37C47.7242 35.3397 47.8144 33.6704 47.81 32C47.8096 30.2188 47.7061 28.4392 47.5 26.67H53.36C54.203 30.0582 54.2338 33.5976 53.45 37H47.54Z"
      fill={colorMap[color]}
    />
  </BaseIcon>
);
