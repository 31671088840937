const i18nPhoneNumbers = {
  LATAM: {
    displayedNumber: '+1 (888) 99 FLARE',
    number: '+18889935273',
  },
  APAC: {
    displayedNumber: '+65 3158 3954',
    number: '+6531583954',
  },
  EMEA: {
    displayedNumber: '+44 20 3514 6970',
    number: '+442035146970',
  },
  DACH: {
    displayedNumber: '+49 89 2555 2276',
    number: '+498925552276',
  },
  AUNZ: {
    displayedNumber: '+61 1300 748 959',
    number: '+611300748959',
  },
};

export const i18nPhoneNumberMapping: {
  [key: string]: { displayedNumber: string; number: string };
} = {
  MX: i18nPhoneNumbers['LATAM'],
  CO: i18nPhoneNumbers['LATAM'],
  AR: i18nPhoneNumbers['LATAM'],
  PE: i18nPhoneNumbers['LATAM'],
  VE: i18nPhoneNumbers['LATAM'],
  CL: i18nPhoneNumbers['LATAM'],
  GT: i18nPhoneNumbers['LATAM'],
  EC: i18nPhoneNumbers['LATAM'],
  CU: i18nPhoneNumbers['LATAM'],
  BO: i18nPhoneNumbers['LATAM'],
  HT: i18nPhoneNumbers['LATAM'],
  DO: i18nPhoneNumbers['LATAM'],
  HN: i18nPhoneNumbers['LATAM'],
  PY: i18nPhoneNumbers['LATAM'],
  SV: i18nPhoneNumbers['LATAM'],
  NI: i18nPhoneNumbers['LATAM'],
  CR: i18nPhoneNumbers['LATAM'],
  PA: i18nPhoneNumbers['LATAM'],
  UY: i18nPhoneNumbers['LATAM'],
  JM: i18nPhoneNumbers['LATAM'],
  TT: i18nPhoneNumbers['LATAM'],
  GY: i18nPhoneNumbers['LATAM'],
  SR: i18nPhoneNumbers['LATAM'],
  BS: i18nPhoneNumbers['LATAM'],
  BZ: i18nPhoneNumbers['LATAM'],
  BB: i18nPhoneNumbers['LATAM'],
  LC: i18nPhoneNumbers['LATAM'],
  VC: i18nPhoneNumbers['LATAM'],
  GD: i18nPhoneNumbers['LATAM'],
  AG: i18nPhoneNumbers['LATAM'],
  DM: i18nPhoneNumbers['LATAM'],
  KN: i18nPhoneNumbers['LATAM'],
  PR: i18nPhoneNumbers['LATAM'],
  GP: i18nPhoneNumbers['LATAM'],
  MQ: i18nPhoneNumbers['LATAM'],
  GF: i18nPhoneNumbers['LATAM'],
  CW: i18nPhoneNumbers['LATAM'],
  AW: i18nPhoneNumbers['LATAM'],
  VI: i18nPhoneNumbers['LATAM'],
  KY: i18nPhoneNumbers['LATAM'],
  SX: i18nPhoneNumbers['LATAM'],
  TC: i18nPhoneNumbers['LATAM'],
  VG: i18nPhoneNumbers['LATAM'],
  BQ: i18nPhoneNumbers['LATAM'],
  AI: i18nPhoneNumbers['LATAM'],
  MS: i18nPhoneNumbers['LATAM'],
  BD: i18nPhoneNumbers['APAC'],
  BT: i18nPhoneNumbers['APAC'],
  IO: i18nPhoneNumbers['APAC'],
  BN: i18nPhoneNumbers['APAC'],
  KH: i18nPhoneNumbers['APAC'],
  CN: {
    displayedNumber: '010 8524 1783',
    number: '01085241783',
  },
  CX: i18nPhoneNumbers['APAC'],
  CC: i18nPhoneNumbers['APAC'],
  CK: i18nPhoneNumbers['APAC'],
  FJ: i18nPhoneNumbers['APAC'],
  PF: i18nPhoneNumbers['APAC'],
  GU: i18nPhoneNumbers['APAC'],
  ID: {
    displayedNumber: '00180349248340049',
    number: '00180349248340049',
  },
  KP: i18nPhoneNumbers['APAC'],
  KI: i18nPhoneNumbers['APAC'],
  KG: i18nPhoneNumbers['APAC'],
  LA: i18nPhoneNumbers['APAC'],
  MO: i18nPhoneNumbers['APAC'],
  MY: {
    displayedNumber: '1800 81 9200',
    number: '1800819200',
  },
  MV: i18nPhoneNumbers['APAC'],
  MH: i18nPhoneNumbers['APAC'],
  FM: i18nPhoneNumbers['APAC'],
  MN: i18nPhoneNumbers['APAC'],
  MM: i18nPhoneNumbers['APAC'],
  NR: i18nPhoneNumbers['APAC'],
  NP: i18nPhoneNumbers['APAC'],
  NC: i18nPhoneNumbers['APAC'],
  NZ: i18nPhoneNumbers['AUNZ'],
  NU: i18nPhoneNumbers['APAC'],
  NF: i18nPhoneNumbers['APAC'],
  MP: i18nPhoneNumbers['APAC'],
  PK: i18nPhoneNumbers['APAC'],
  PW: i18nPhoneNumbers['APAC'],
  PG: i18nPhoneNumbers['APAC'],
  PH: {
    displayedNumber: '180013120014',
    number: '180013120014',
  },
  PN: i18nPhoneNumbers['APAC'],
  WS: i18nPhoneNumbers['APAC'],
  SB: i18nPhoneNumbers['APAC'],
  LK: i18nPhoneNumbers['APAC'],
  TW: {
    displayedNumber: '00801491427',
    number: '00801491427',
  },
  TJ: i18nPhoneNumbers['APAC'],
  TH: {
    displayedNumber: '02 508 8489',
    number: '025088489',
  },
  TL: i18nPhoneNumbers['APAC'],
  TK: i18nPhoneNumbers['APAC'],
  TO: i18nPhoneNumbers['APAC'],
  TM: i18nPhoneNumbers['APAC'],
  TV: i18nPhoneNumbers['APAC'],
  UZ: i18nPhoneNumbers['APAC'],
  VU: i18nPhoneNumbers['APAC'],
  VN: i18nPhoneNumbers['APAC'],
  WF: i18nPhoneNumbers['APAC'],

  AF: i18nPhoneNumbers['EMEA'],
  AL: i18nPhoneNumbers['EMEA'],
  DZ: i18nPhoneNumbers['EMEA'],
  AD: i18nPhoneNumbers['EMEA'],
  AO: i18nPhoneNumbers['EMEA'],
  AM: i18nPhoneNumbers['EMEA'],
  AT: i18nPhoneNumbers['DACH'],
  AZ: i18nPhoneNumbers['EMEA'],
  BH: i18nPhoneNumbers['EMEA'],
  BY: i18nPhoneNumbers['EMEA'],
  BE: i18nPhoneNumbers['EMEA'],
  BJ: i18nPhoneNumbers['EMEA'],
  BA: i18nPhoneNumbers['EMEA'],
  BW: i18nPhoneNumbers['EMEA'],
  BG: i18nPhoneNumbers['EMEA'],
  BF: i18nPhoneNumbers['EMEA'],
  BI: i18nPhoneNumbers['EMEA'],
  CM: i18nPhoneNumbers['EMEA'],
  CV: i18nPhoneNumbers['EMEA'],
  CF: i18nPhoneNumbers['EMEA'],
  TD: i18nPhoneNumbers['EMEA'],
  KM: i18nPhoneNumbers['EMEA'],
  CD: i18nPhoneNumbers['EMEA'],
  CG: i18nPhoneNumbers['EMEA'],
  CI: i18nPhoneNumbers['EMEA'],
  HR: i18nPhoneNumbers['EMEA'],
  CY: i18nPhoneNumbers['EMEA'],
  CZ: i18nPhoneNumbers['EMEA'],
  DK: i18nPhoneNumbers['EMEA'],
  DJ: i18nPhoneNumbers['EMEA'],
  EG: i18nPhoneNumbers['EMEA'],
  GQ: i18nPhoneNumbers['EMEA'],
  ER: i18nPhoneNumbers['EMEA'],
  EE: i18nPhoneNumbers['EMEA'],
  ET: i18nPhoneNumbers['EMEA'],
  FK: i18nPhoneNumbers['EMEA'],
  FO: i18nPhoneNumbers['EMEA'],
  FI: i18nPhoneNumbers['EMEA'],
  GA: i18nPhoneNumbers['EMEA'],
  GM: i18nPhoneNumbers['EMEA'],
  GE: i18nPhoneNumbers['EMEA'],
  GH: i18nPhoneNumbers['EMEA'],
  GI: i18nPhoneNumbers['EMEA'],
  GR: i18nPhoneNumbers['EMEA'],
  GL: i18nPhoneNumbers['EMEA'],
  GG: i18nPhoneNumbers['EMEA'],
  GN: i18nPhoneNumbers['EMEA'],
  GW: i18nPhoneNumbers['EMEA'],
  VA: i18nPhoneNumbers['EMEA'],
  HU: i18nPhoneNumbers['EMEA'],
  IS: i18nPhoneNumbers['EMEA'],
  IR: i18nPhoneNumbers['EMEA'],
  IQ: i18nPhoneNumbers['EMEA'],
  IE: i18nPhoneNumbers['EMEA'],
  IM: i18nPhoneNumbers['EMEA'],
  IL: i18nPhoneNumbers['EMEA'],
  IT: i18nPhoneNumbers['EMEA'],
  JE: i18nPhoneNumbers['EMEA'],
  JO: i18nPhoneNumbers['EMEA'],
  KZ: i18nPhoneNumbers['EMEA'],
  KE: i18nPhoneNumbers['EMEA'],
  KW: i18nPhoneNumbers['EMEA'],
  LV: i18nPhoneNumbers['EMEA'],
  LB: i18nPhoneNumbers['EMEA'],
  LS: i18nPhoneNumbers['EMEA'],
  LR: i18nPhoneNumbers['EMEA'],
  LY: i18nPhoneNumbers['EMEA'],
  LI: i18nPhoneNumbers['DACH'],
  LT: i18nPhoneNumbers['EMEA'],
  LU: i18nPhoneNumbers['EMEA'],
  MK: i18nPhoneNumbers['EMEA'],
  MG: i18nPhoneNumbers['EMEA'],
  MW: i18nPhoneNumbers['EMEA'],
  ML: i18nPhoneNumbers['EMEA'],
  MT: i18nPhoneNumbers['EMEA'],
  MR: i18nPhoneNumbers['EMEA'],
  MU: i18nPhoneNumbers['EMEA'],
  YT: i18nPhoneNumbers['EMEA'],
  MD: i18nPhoneNumbers['EMEA'],
  MC: i18nPhoneNumbers['EMEA'],
  ME: i18nPhoneNumbers['EMEA'],
  MA: i18nPhoneNumbers['EMEA'],
  MZ: i18nPhoneNumbers['EMEA'],
  NA: i18nPhoneNumbers['EMEA'],
  NL: i18nPhoneNumbers['EMEA'],
  NE: i18nPhoneNumbers['EMEA'],
  NG: i18nPhoneNumbers['EMEA'],
  NO: i18nPhoneNumbers['EMEA'],
  OM: i18nPhoneNumbers['EMEA'],
  PS: i18nPhoneNumbers['EMEA'],
  PL: i18nPhoneNumbers['EMEA'],
  PT: {
    displayedNumber: '+351 (21) 1230932 ',
    number: '+351211230932 ',
  },
  QA: i18nPhoneNumbers['EMEA'],
  RE: i18nPhoneNumbers['EMEA'],
  RO: i18nPhoneNumbers['DACH'],
  RU: i18nPhoneNumbers['EMEA'],
  RW: i18nPhoneNumbers['EMEA'],
  SH: i18nPhoneNumbers['EMEA'],
  SM: i18nPhoneNumbers['EMEA'],
  ST: i18nPhoneNumbers['EMEA'],
  SA: i18nPhoneNumbers['EMEA'],
  SN: i18nPhoneNumbers['EMEA'],
  RS: i18nPhoneNumbers['EMEA'],
  SC: i18nPhoneNumbers['EMEA'],
  SL: i18nPhoneNumbers['EMEA'],
  SK: i18nPhoneNumbers['EMEA'],
  SI: i18nPhoneNumbers['EMEA'],
  SO: i18nPhoneNumbers['EMEA'],
  ZA: i18nPhoneNumbers['EMEA'],
  SS: i18nPhoneNumbers['EMEA'],
  SD: i18nPhoneNumbers['EMEA'],
  SJ: i18nPhoneNumbers['EMEA'],
  SZ: i18nPhoneNumbers['EMEA'],
  SE: i18nPhoneNumbers['EMEA'],
  CH: i18nPhoneNumbers['DACH'],
  SY: i18nPhoneNumbers['EMEA'],
  TZ: i18nPhoneNumbers['EMEA'],
  TG: i18nPhoneNumbers['EMEA'],
  TN: i18nPhoneNumbers['EMEA'],
  TR: i18nPhoneNumbers['EMEA'],
  UG: i18nPhoneNumbers['EMEA'],
  UA: i18nPhoneNumbers['EMEA'],
  AE: i18nPhoneNumbers['EMEA'],
  EH: i18nPhoneNumbers['EMEA'],
  YE: i18nPhoneNumbers['EMEA'],
  ZM: i18nPhoneNumbers['EMEA'],
  ZW: i18nPhoneNumbers['EMEA'],
  AU: i18nPhoneNumbers['AUNZ'],
  DE: i18nPhoneNumbers['DACH'],

  US: {
    displayedNumber: '+1 (888) 99 FLARE',
    number: '+18889935273',
  },
  GB: {
    displayedNumber: '+44 20 3514 6970',
    number: '+442035146970',
  },
  BR: {
    displayedNumber: '+55 (11) 3230 4523',
    number: '+551132304523',
  },
  FR: {
    displayedNumber: '+33 7 57 90 52 73',
    number: '+33757905273',
  },
  SG: {
    displayedNumber: '6797 6901',
    number: '6567976901',
  },
  KR: {
    displayedNumber: '00798142030192',
    number: '00798142030192',
  },
  JP: {
    displayedNumber: '034 578 0379',
    number: '0345780379',
  },
  HK: {
    displayedNumber: '3008 3639',
    number: '30083639',
  },
  ES: {
    displayedNumber: '+34 518 880 290',
    number: '+34518880290',
  },
  i18n: {
    displayedNumber: '+1 (650) 319 8930',
    number: '+16503198930',
  },
  LP: {
    displayedNumber: '+1 (888) 274-3482',
    number: '+18882743482',
  },
  IN: {
    displayedNumber: '0008000501996',
    number: '0008000501996',
  },
};
