import { ContentfulNavigationItem } from '../../../../contentful/content-types/navigationitem';

export interface TabProps {
  selectedTab: string;
  tabData?: ContentfulNavigationItem;
}

export interface TabState {
  tabProps: TabProps;
}

export const initialTabState: TabState = {
  tabProps: {
    selectedTab: '',
  },
};

export enum TAB_TYPES {
  SWITCH = 'SWITCH_TAB',
  RESET = 'RESET',
}

export interface TabAction {
  type: TAB_TYPES;
  payload?: TabProps;
}

export const tabMenuReducer = (state = initialTabState, action: TabAction): TabState => {
  switch (action.type) {
    case TAB_TYPES.SWITCH:
      return {
        tabProps: {
          ...state.tabProps,
          ...action.payload,
        },
      };
    case TAB_TYPES.RESET:
      return {
        ...state,
        ...initialTabState,
      };
    default:
      return state;
  }
};
