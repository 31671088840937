export const langCodeList = {
  'en-us': { dropdownTitle: 'English', value: 'English', isOrphanLang: 'false' },
  'en-gb': { dropdownTitle: 'English (United Kingdom)', value: 'English', isOrphanLang: 'false' },
  'en-ca': { dropdownTitle: 'English (Canada)', value: 'English', isOrphanLang: 'false' },
  'en-au': { dropdownTitle: 'English (Australia)', value: 'English', isOrphanLang: 'false' },
  'en-in': { dropdownTitle: 'English (India)', value: 'English', isOrphanLang: 'false' },
  'de-de': { dropdownTitle: 'Deutsch', value: 'Deutsch', isOrphanLang: 'false' },
  'es-es': { dropdownTitle: 'Español (España)', value: 'Español', isOrphanLang: 'false' },
  'es-la': { dropdownTitle: 'Español (Latinoamérica)', value: 'Español', isOrphanLang: 'false' },
  'fr-fr': { dropdownTitle: 'Français', value: 'Français', isOrphanLang: 'false' },
  'it-it': { dropdownTitle: 'Italiano', value: 'Italiano', isOrphanLang: 'false' },
  'ja-jp': { dropdownTitle: '日本語', value: '日本語', isOrphanLang: 'false' },
  'ko-kr': { dropdownTitle: '한국어', value: '한국어', isOrphanLang: 'false' },
  'pt-br': { dropdownTitle: 'Português', value: 'Português', isOrphanLang: 'false' },
  'zh-tw': { dropdownTitle: '繁體中文', value: '繁體中文', isOrphanLang: 'false' },
  'zh-cn': { dropdownTitle: '简体中文', value: '简体中文', isOrphanLang: 'false' },
  'nl-nl': { dropdownTitle: '', value: '', isOrphanLang: 'true' },
  'id-id': { dropdownTitle: '', value: '', isOrphanLang: 'true' },
  'th-th': { dropdownTitle: '', value: '', isOrphanLang: 'true' },
  'ru-ru': { dropdownTitle: '', value: '', isOrphanLang: 'true' },
  'sv-se': { dropdownTitle: '', value: '', isOrphanLang: 'true' },
  'vi-vn': { dropdownTitle: '', value: '', isOrphanLang: 'true' },
} as any;
