import isEmpty from 'lodash/isEmpty';
import { useState, useEffect } from 'react';
import { psaBannerInterface } from '../../contentful/content-types/psa-banner';

export interface ContentfulPsaBannerInterace extends Partial<psaBannerInterface> {
  isPsaBannerClosed?: boolean;
}
export function useContentfulPsaBanner(
  banner: psaBannerInterface
): [undefined | ContentfulPsaBannerInterace, () => void] {
  const [psaBanner, setPsaBanner] = useState<ContentfulPsaBannerInterace>();

  let psaBannerLocalStorage;
  let parsedPsaBanner: { contentfulId: string; isPsaBannerClosed: boolean };
  useEffect(() => {
    psaBannerLocalStorage = localStorage.getItem(`psaBanner-${banner.contentfulId}`);
    parsedPsaBanner = JSON.parse(psaBannerLocalStorage || '{}');
    // This is the first time this user is visiting (localstorage is empty)
    if (!psaBannerLocalStorage) {
      const newUserBanner = { ...banner, isOpen: true, isPsaBannerClosed: false };
      setPsaBanner(newUserBanner);
      localStorage.setItem(
        `psaBanner-${banner.contentfulId}`,
        JSON.stringify({
          contentfulId: newUserBanner.contentfulId,
          isPsaBannerClosed: false,
        })
      );
      return;
    }
    //This user is has visited before and chose to close the banner
    if (!isEmpty(parsedPsaBanner) && parsedPsaBanner.isPsaBannerClosed) {
      setPsaBanner({ ...banner, isPsaBannerClosed: true });
      return;
    }

    //This is a returning user who hasn't  closed the banner
    if (!isEmpty(parsedPsaBanner) && !parsedPsaBanner.isPsaBannerClosed) {
      setPsaBanner({ ...banner, isPsaBannerClosed: false });
      return;
    }
  }, []);

  const closeBanner = () => {
    setPsaBanner({ ...psaBanner, isPsaBannerClosed: true });
    const localStorageObj = {
      contentfulId: psaBanner?.contentfulId,
      isPsaBannerClosed: true,
    };

    localStorage.setItem(
      `psaBanner-${localStorageObj.contentfulId}`,
      JSON.stringify(localStorageObj)
    );
  };
  return [psaBanner, closeBanner];
}
