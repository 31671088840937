import React from 'react';
import { CFChevronRight } from '../../common/CFIcons/CFChevronRight';
import { Div, A, Col, P, Ul, Li, Span, Button } from '../../common/Elements';
import { DisplayIndex } from '../../common/Elements/types';
import { Icon } from '../../common/Icon';
import { contactNumber } from './footer-constants';
import { ColumnData } from './footer.interfaces';

interface FooterColumnProps {
  column: ColumnData;
  columnIndex: number;
  contactField: string;
}

const FooterColumn: React.FC<FooterColumnProps> = React.memo(
  ({ column, columnIndex, contactField }: FooterColumnProps) => {
    const [mobileMenuStyle, setMobileMenuStyle] = React.useState<DisplayIndex>('none');

    const toggleMobileMenu = () => {
      setMobileMenuStyle(mobileMenuStyle === 'none' ? 'block' : 'none');
    };

    const headingFontSize = columnIndex === 0 ? 3 : 2;
    const toggleButtonDisplay = columnIndex === 0 ? 'none' : 'inline-block';
    const menuDisplay = columnIndex === 0 ? 'block' : mobileMenuStyle;
    return (
      <Col lg={2}>
        <Div
          border={['bottom', 'bottom', 'bottom', 'none']}
          borderColor="white"
          paddingVertical={[2, 2, 2, 0]}
        >
          <P
            fontWeight={6}
            marginTop={0}
            display="flex"
            justifyContent="between"
            marginBottom={[0, 0, 0, 1]}
            lineHeight="copy"
            fontSize={[headingFontSize, headingFontSize, headingFontSize, 3]}
          >
            {column.title}
            <Button
              display={[toggleButtonDisplay, toggleButtonDisplay, toggleButtonDisplay, 'none']}
              border="none"
              backgroundColor="transparent"
              rotate={mobileMenuStyle === 'block' ? 270 : 90}
              onClick={toggleMobileMenu}
            >
              <CFChevronRight size={16} color="white" />
            </Button>
          </P>
          <Ul
            display={[menuDisplay, menuDisplay, menuDisplay, 'block']}
            margin={0}
            padding={0}
            marginTop={[2, 2, 2, 0]}
            className="list"
          >
            {column.items.map(item => (
              <Li key={item.contentfulId} marginTop={1} lineHeight={6}>
                <A
                  color={columnIndex === 0 ? 'blue2' : 'white'}
                  fontSize={2}
                  fontWeight={4}
                  href={item.url}
                  className={item.customClassName}
                >
                  {item.title}
                </A>
              </Li>
            ))}
            {columnIndex === 0 && (
              <>
                <Li>
                  <Span fontSize={2} fontWeight={4} color="blue2">
                    {contactField}
                  </Span>
                </Li>
                <Li>
                  <A href={`tel:${contactNumber.number}`} fontSize={2} fontWeight={4} color="blue2">
                    {contactNumber.display}
                  </A>
                </Li>
              </>
            )}
          </Ul>
        </Div>
      </Col>
    );
  }
);

export default FooterColumn;
