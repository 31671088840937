import React, { Reducer } from 'react';
import { Row, Col, Div, Button } from '../../../../common/Elements';
import { useHeaderContext } from '../../header.context';
import { MenuSide } from './MenuSide';
import { MenuMain } from './MenuMain';
import {
  initialTabState,
  tabMenuReducer,
  TabAction,
  TabState,
  TAB_TYPES,
} from '../../reducers/tabmenu.reducer';
import { TabContext } from '../../tabs.context';
import { Merchandizing } from './Merchandizing';
import { CFXExit } from '../../../../common/CFIcons/CFXExit';
import { MODAL_TYPES } from '../../reducers/modal.reducer';
import { ContentfulNavigation } from '../../../../../contentful/content-types/navigation';
import Media from 'react-media';
import { trackNavigationData } from '../../utils/navigation.tracking';

interface Props {
  rightNavData: ContentfulNavigation;
}

interface MenuProps extends Props {
  isMobile: boolean;
}

const Menu: React.FC<MenuProps> = ({ rightNavData, isMobile }) => {
  const [tab, dispatchTab] = React.useReducer<Reducer<TabState, TabAction>>(
    tabMenuReducer,
    initialTabState
  );

  const { modal, dispatch } = useHeaderContext();

  const hasModalMerchandize = tab.tabProps.tabData?.navigationLinks?.filter(
    link => link.specialLinkType === 'MERCHANDIZE'
  )?.length;

  React.useEffect(() => {
    if (modal.modalProps.modalData && modal.modalProps.modalData?.navigationItems && !isMobile) {
      dispatchTab({
        type: TAB_TYPES.SWITCH,
        payload: {
          selectedTab: modal.modalProps.modalData?.navigationItems[0].contentfulId,
          tabData: modal.modalProps.modalData?.navigationItems[0],
        },
      });
    }
  }, [modal.modalProps.modalData]);

  return (
    <TabContext.Provider value={{ tab, dispatch: dispatchTab }}>
      <Row flexWrap={['wrap', 'wrap', 'wrap', 'nowrap']} position="relative" flex="auto">
        <Button
          position="absolute"
          display={['none', 'none', 'none', 'block']}
          zIndex={2}
          backgroundColor="transparent"
          padding={0}
          className="right-1 top--2 pointer"
          role="button"
          onClick={() => {
            dispatch({
              type: MODAL_TYPES.HIDE,
            });
            trackNavigationData({
              element: 'button',
              label: `mainnav|closemenu_${tab?.tabProps?.tabData?.name}`,
            });
          }}
        >
          <CFXExit size={32} color="black" />
        </Button>
        <Col lg={3}>
          <MenuSide rightNavData={rightNavData} />
        </Col>
        <Col display={['none', 'none', 'none', 'block']} lg={!!hasModalMerchandize ? 6 : 9}>
          <Div paddingLeft={[0, 0, 0, 3]}>
            <MenuMain />
          </Div>
        </Col>
        {!!hasModalMerchandize && (
          <Col display={['none', 'none', 'none', 'flex']} lg={3}>
            <Div display="flex" width="100%" justifyContent="end">
              <Merchandizing />
            </Div>
          </Col>
        )}
      </Row>
    </TabContext.Provider>
  );
};

export const MegaMenu: React.FC<Props> = ({ rightNavData }) => {
  return (
    <Media
      queries={{
        small: '(max-width: 991px)',
        large: '(min-width: 992px)',
      }}
      defaultMatches={{ large: true }}
    >
      {matches => <Menu isMobile={!matches.large} rightNavData={rightNavData} />}
    </Media>
  );
};
