import React, { FC } from 'react';
import { AppContextConsumer } from '../context/app.context';
import { Link } from '@reach/router';
import { makeStyledComponent } from '../Elements/Elements';
import { isDirectLink, resolveUrl } from '../../../common/util/path';

export interface LocaleLinkProps extends React.HTMLAttributes<HTMLAnchorElement> {
  to: string;
  variant?: 'link' | 'anchor';
  openInNewTab?: boolean;
  className: string;
}

const LocaleLinkUnstyled: FC<LocaleLinkProps> = ({
  children,
  openInNewTab,
  to,
  variant = 'link',
  className,
  ...props
}) => {
  return (
    <AppContextConsumer>
      {({ locale }) =>
        isDirectLink(to) || variant === 'anchor' ? (
          <a
            target={openInNewTab ? '_blank' : ''}
            href={resolveUrl(locale, to)}
            className={className}
            {...props}
          >
            {children}
          </a>
        ) : (
          <Link to={resolveUrl(locale, to)} className={className} {...props}>
            {children}
          </Link>
        )
      }
    </AppContextConsumer>
  );
};

export const LocaleLink = makeStyledComponent(LocaleLinkUnstyled);
