import { ImageLink } from './footer.interfaces';

export const socialLinks: ImageLink[] = [
  {
    url: 'https://www.facebook.com/cloudflare',
    iconType: 'facebook',
    title: 'Facebook',
  },
  {
    url: 'https://twitter.com/cloudflare',
    iconType: 'twitter',
    title: 'Twitter',
  },
  {
    url: 'https://www.linkedin.com/company/cloudflare',
    iconType: 'linkedin',
    title: 'LinkedIn',
  },
  {
    url: 'https://www.youtube.com/cloudflare',
    iconType: 'youtube',
    title: 'Youtube',
  },
];

export const contactNumber = {
  number: '18889935273',
  display: '+1 (888) 99 FLARE',
};
