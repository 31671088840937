import React from 'react';
import Modal from 'react-modal';
import { Div, Header } from '../../../common/Elements';
import { useHeaderContext } from '../header.context';
import { MainHeader } from '../MainHeader';
import { MegaMenu } from './ModalComponents/MegaMenu';
import { ContentfulNavigation } from '../../../../contentful/content-types/navigation';

interface Props {
  navData: ContentfulNavigation;
  rightNavData: ContentfulNavigation;
  utilityNav: ContentfulNavigation;
}

export const MenuModal: React.FC<Props> = ({ navData, utilityNav, rightNavData }) => {
  const { modal } = useHeaderContext();

  const hasSelectedMenu = modal.modalProps.selectedMenu;

  const mobileVerticalPadding = hasSelectedMenu ? 3 : 0;

  return (
    <Modal
      closeTimeoutMS={200}
      isOpen={modal.open}
      className="bg-br-lightBlue flex flex-column h-auto min-h-100 outline-0"
      overlayClassName="fixed top-0 bottom-0 left-0 right-0 bg-br-lightBlue z-max overflow-y-scroll br-refresh"
      contentLabel="nav-menu"
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      ariaHideApp={true}
      bodyOpenClassName="overflow-hidden"
    >
      <Header display="flex" flexDirection="column" flex="auto" id="modal-nav">
        <MainHeader
          rightNavData={rightNavData}
          utilityNav={utilityNav}
          navData={navData}
          modalNav
        />
        <Div
          width="100%"
          display="flex"
          flex="auto"
          flexDirection="column"
          paddingHorizontal={[3, 3, 3, 6]}
          style={{
            maxWidth: '1440px',
            margin: '0 auto',
            height: 'auto',
            minHeight: hasSelectedMenu ? '100%' : '',
            overflow: hasSelectedMenu ? 'overflow-hidden-l' : '',
          }}
          paddingVertical={[mobileVerticalPadding, mobileVerticalPadding, mobileVerticalPadding, 5]}
        >
          <MegaMenu rightNavData={rightNavData} />
        </Div>
      </Header>
    </Modal>
  );
};
