import { ContentfulNavigationItem } from '../../../../contentful/content-types/navigationitem';

export interface ModalProps {
  selectedMenu: string;
  modalData?: ContentfulNavigationItem;
}

export interface ModalState {
  open: boolean;
  modalProps: ModalProps;
}

export const initialModalState: ModalState = {
  open: false,
  modalProps: {
    selectedMenu: '',
  },
};

export enum MODAL_TYPES {
  SHOW = 'SHOW_MODAL',
  CLEAR_SELECTED = 'CLEAR_SELECTED',
  HIDE = 'HIDE_MODAL',
}

export interface ModalAction {
  type: MODAL_TYPES;
  payload?: ModalProps;
}

export const menuModalReducer = (state = initialModalState, action: ModalAction): ModalState => {
  switch (action.type) {
    case MODAL_TYPES.SHOW:
      return {
        open: true,
        modalProps: {
          ...state.modalProps,
          ...action.payload,
        },
      };
    case MODAL_TYPES.CLEAR_SELECTED:
      return {
        ...state,
        modalProps: {
          selectedMenu: '',
          modalData: undefined,
        },
      };
    case MODAL_TYPES.HIDE:
      return {
        ...state,
        ...initialModalState,
      };
    default:
      return state;
  }
};
