import React, { FC, useState, useEffect } from 'react';
import { Locale, defaultLocale } from '../../../common/util/locales';
import { AppContextProps } from '../../../common/interfaces/locale-props.interface';

export type AppContext = {
  host: string;
  protocol: string;
  locale: Locale;
  pathname: string;
};

const defaultState = {
  host: 'localhost',
  pathname: '/',
  protocol: 'https:',
  locale: defaultLocale,
};

export const AppStateContext = React.createContext<AppContext>(defaultState);
export const AppProvider = AppStateContext.Provider;
export const AppContextConsumer = AppStateContext.Consumer;

const AppContextProvider: FC<{ pageContext: AppContextProps }> = ({ children, pageContext }) => {
  const { locale, pathname } = pageContext;
  const [appState, setAppState] = useState({ ...defaultState, locale, pathname });

  useEffect(() => {
    const url = new URL(window.location.href);

    let { host, protocol } = url;

    setAppState({
      host,
      pathname,
      protocol,
      locale,
    });
  }, []);

  if (appState === defaultState) {
    return <>{children}</>;
  }

  return <AppProvider value={appState}>{children}</AppProvider>;
};

export default AppContextProvider;
