import React from 'react';
import { BaseIcon } from './BaseIcon';
import { colorMap, IconProps } from './icon.interface';

export const CFCheck: React.FC<IconProps> = ({ size, color, className }) => (
  <BaseIcon className={className} size={size}>
    <path
      d="M27.91 38.1101L20.01 29.8601L17.12 32.6301L27.86 43.8501L46.85 24.6501L44.01 21.8401L27.91 38.1101Z"
      fill={colorMap[color]}
    />
  </BaseIcon>
);
