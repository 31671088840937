import { gtm } from '../../../../common/scripts/gtm';

export interface EVENT_TRACKING_DATA {
  event: string;
  eventType: string;
  eventCategory: string;
  eventElement: string;
  eventAction: string;
  eventLabel: string;
}

export interface NAV_TRACKING_DATA {
  element: 'button' | 'link' | 'dropdown';
  label: string;
}

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const trackNavigationData = ({ element, label }: NAV_TRACKING_DATA): void => {
  trackData({
    event: 'clickEvent',
    eventType: 'navigation',
    eventCategory: 'navigation',
    eventAction: `click-${element}`,
    eventElement: `Navigation ${capitalizeFirstLetter(element)}`,
    eventLabel: label,
  });
};

export const trackData = (trackingData: EVENT_TRACKING_DATA): void => {
  gtm(trackingData);
};
