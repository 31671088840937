import React, { useEffect, ReactNode, useRef, useState } from 'react';
interface DropdownProps {
  renderToggle: (isOpen: boolean, setIsOpen: () => void) => ReactNode;
  renderDropdownContent: (isOpen: Boolean) => ReactNode;
  styles?: React.CSSProperties;
}

const Dropdown: React.FC<DropdownProps> = ({ renderDropdownContent, renderToggle, styles }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const onClickOutside = (e: MouseEvent) => {
      if (ref.current != null && !ref.current.contains(e.target as any)) {
        setIsOpen(!isOpen);
      }
    };
    if (isOpen) {
      window.addEventListener('click', onClickOutside);
    }
    return () => {
      window.removeEventListener('click', onClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="relative flex" ref={ref}>
      {renderToggle(isOpen, handleToggle)}
      {isOpen && (
        <div
          className="absolute z-max top-100 mw-8"
          style={{
            minWidth: '155px',
            right: '0',
            padding: '8px',
            backgroundColor: 'white',
            boxShadow: '0 3px 4px rgba(0, 0, 0, 0.25)',
            transition: 'all 0.4s ease 0s',
            ...styles,
          }}
        >
          {renderDropdownContent(isOpen)}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
