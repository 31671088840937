import * as React from 'react';
import ReactMarkdownWithHtml from 'react-markdown/with-html';
import { ReactMarkdownProps } from 'react-markdown';
import { P, A, makeStyledComponent, Div, Pre, Li } from './Elements';
import { useLocale } from '../../common/hooks/useLocale';
import { useGlobalVariables } from '../../common/hooks/useGlobalVariables';
import { interpolateGlobalVariables } from '../../common/util/interpolateGlobalVariables';
import { isTargetEnv, TargetEnv } from '../../common/util/TargetEnv';
import { graphql, useStaticQuery } from 'gatsby';

interface MarkdownBlockParams {
  className?: string;
  href?: string;
  listStyle?: 'feature';
  pClassName?: string;
  paragraphStyle?: 1 | 2 | 3;
  source?: ReactMarkdownProps['source'];
  renderers?: ReactMarkdownProps['renderers'];
}

const metaDataQuery = graphql`
  query {
    site {
      siteMetadata {
        targetEnv
      }
    }
  }
`;

export const UnstyledMarkdownBlock: React.FC<MarkdownBlockParams> = props => {
  const locale = useLocale();
  const globalVariables = useGlobalVariables();
  const targetEnv = useStaticQuery(metaDataQuery)?.site?.siteMetadata?.targetEnv;
  const text = props.source || String(props.children);
  const memoizedText = React.useMemo(
    () => (!text ? null : interpolateGlobalVariables(text, locale, globalVariables.json_values)),
    [locale, text]
  );

  if (!memoizedText) return null;

  const listRenderer = (children: any) => <ul className="markdown-feature-list">{children}</ul>;
  const listItemRenderer = (children: any) => (
    <Li className="markdown-feature-listitem body-1 db" paddingLeft={3} position="relative">
      {children}
    </Li>
  );

  return (
    <ErrorBoundary
      source={memoizedText}
      targetEnv={isTargetEnv(targetEnv) ? targetEnv : 'production'}
    >
      <ReactMarkdownWithHtml
        className={`markdown-block ${props.className || ''}`}
        source={memoizedText}
        escapeHtml={false}
        renderers={{
          strong: ({ children }) => <strong className="fw7">{children}</strong>,
          paragraph: ({ children }) => (
            <P
              marginBottom={4}
              className={`${props.paragraphStyle ? `body-${props.paragraphStyle}` : 'body-1'}${
                props.pClassName ? ' ' + props.pClassName : ''
              }`}
            >
              {children}
            </P>
          ),
          link: ({ children, href }) => (
            <A
              hovered={{ color: 'blue0' }}
              fontWeight={4}
              color={'blue1'}
              href={href}
              className="blue1"
            >
              {children}
            </A>
          ),
          list: ({ children }) => listRenderer(children),
          listItem: ({ children }) => listItemRenderer(children),
          ...props.renderers,
        }}
      />
    </ErrorBoundary>
  );
};

export const MarkdownBlock = makeStyledComponent(UnstyledMarkdownBlock);

class ErrorBoundary extends React.Component<{ source?: string; targetEnv: TargetEnv }> {
  state = { hasError: false };

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    if (this.props.targetEnv === 'production') {
      if (this.state.hasError) {
        return null;
      }

      return this.props.children;
    }

    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Div padding={3} backgroundColor="red8" color="red0">
          This Markdown block threw an error. It's likely due to invalid markup. Please review the
          source:{' '}
          <Pre padding={2} backgroundColor="gray8" color="black">
            {this.props.source || ''}
          </Pre>
        </Div>
      );
    }

    return this.props.children;
  }
}
