import React from 'react';
import { ContentfulNavigationItem } from '../../../../../contentful/content-types/navigationitem';
import { Col, Div, Row } from '../../../../common/Elements';
import { LocaleLink } from '../../../../common/links/LocaleLink';
import Text from '../../../../common/Text/Text';
import { useHeaderContext } from '../../header.context';
import { trackNavigationData } from '../../utils/navigation.tracking';
import { MenuItem } from './MenuItem';

interface Props {
  group: ContentfulNavigationItem;
  hasModalMerchandize?: boolean;
}

export const MenuItemGroup: React.FC<Props> = ({ group, hasModalMerchandize }) => {
  const colspan = hasModalMerchandize ? 6 : 4;
  const navLinks = group.navigationLinks?.filter(link => !link.specialLinkType);
  const { modal } = useHeaderContext();

  return (
    <Col flex="none" lg={colspan} marginBottom={[2, 2, 2, 6]}>
      <Div marginBottom={2} paddingBottom={2} border="bottom" borderColor="gray2">
        {group.mainLink ? (
          <LocaleLink
            to={group.mainLink?.url}
            onClick={() =>
              trackNavigationData({
                element: 'link',
                label: `mainnav|${modal?.modalProps?.modalData?.name}_${group.name}:[${group.mainLink?.url}]`,
              })
            }
          >
            <Text variant="body1-bold" color="orange0">
              {group.name}
            </Text>
          </LocaleLink>
        ) : (
          <Text variant="body1-bold" color="orange0">
            {group.name}
          </Text>
        )}
      </Div>
      <Row>
        {navLinks?.map(link => (
          <MenuItem grouped link={link} key={link.contentfulId} />
        ))}
      </Row>
    </Col>
  );
};
