import React from 'react';
import { BaseIcon } from './BaseIcon';
import { colorMap, IconProps } from './icon.interface';

export const CFXExit: React.FC<IconProps> = ({ size, color, className }) => (
  <BaseIcon className={className} size={size}>
    <path
      d="M57.361 54.419L34.783 32.09L57.1119 9.51197L54.268 6.69897L31.9389 29.278L9.36095 6.94897L6.54895 9.79297L29.127 32.122L6.79895 54.699L9.64295 57.512L31.971 34.934L54.549 57.263L57.361 54.419Z"
      fill={colorMap[color]}
    />
  </BaseIcon>
);
