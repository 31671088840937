import React from 'react';
import { Div, H1, H2, H3, H4, H5, P, Span } from '../Elements';
import { StyleSystemProps } from '../Elements/Elements';
import { HTMLTextTags, TextVariants } from '../Elements/types';
import { TextVariantMap } from './Text.interface';

export interface TextProps extends Partial<StyleSystemProps> {
  tag?: HTMLTextTags;
  variant?: TextVariants;
  hovered?: Partial<StyleSystemProps>;
  className?: string;
}

const Text: React.FC<TextProps> = ({
  color = 'black',
  tag = 'Div',
  variant = 'body1',
  children,
  ...props
}) => {
  const components: Partial<{ [K in HTMLTextTags]: React.FC<Partial<StyleSystemProps>> }> = {
    Div,
    H1,
    H2,
    H3,
    H4,
    H5,
    P,
    Span,
  };
  const textStyle = TextVariantMap[variant];
  const Tag = components[tag];
  return Tag ? (
    <Tag color={color} {...textStyle} {...props}>
      {children}
    </Tag>
  ) : null;
};

export default Text;
