import { graphql, useStaticQuery } from 'gatsby';
import { GlobalVariables } from '../fragments/MRKConfig';
import { defaultLocale } from '../util/locales';
import { useLocale } from './useLocale';

const globalVariablesQuery = graphql`
  query {
    allMrkConfigGlobalVariables {
      nodes {
        ...GlobalVariablesFragment
      }
    }
  }
`;

export function useGlobalVariables() {
  const locale = useLocale();
  const result = useStaticQuery(globalVariablesQuery);
  const nodes: GlobalVariables[] = result?.allMrkConfigGlobalVariables?.nodes || [];
  const englishStrings = nodes.find(globalVariables => globalVariables.locale === defaultLocale);

  if (!englishStrings) {
    throw new Error('Could not find en-US locale global variables entry');
  }

  return nodes.find(globalVariables => globalVariables.locale === locale) || englishStrings;
}
