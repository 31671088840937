import React from 'react';

interface LogoProps {
  variant: 'mark' | 'horizontal' | 'vertical' | 'custom';
  color: 'default' | 'dark' | 'light' | 'text-light';
  partnernetwork?: boolean;
  trademarked?: boolean;
  className?: string;
  width?: string | 'auto';
  height?: string | 'auto';
  customHeaderLogoUrl?: string | undefined;
}

export const Logo: React.FC<LogoProps> = ({
  variant,
  color,
  partnernetwork,
  trademarked,
  width,
  height,
  customHeaderLogoUrl,
}) => {
  switch (variant) {
    case 'horizontal':
      return (
        <svg
          width="602"
          height="88"
          viewBox="0 0 602 88"
          fill="none"
          style={{ width: width || '100%', height: height || 'auto' }}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M132.22 86.7559L133.202 83.3054C134.393 79.2211 133.949 75.4185 131.963 72.6487C130.141 70.0902 127.104 68.5879 123.413 68.4001L53.5656 67.5081C53.0984 67.4847 52.7013 67.2734 52.4677 66.9213C52.2341 66.5692 52.164 66.0998 52.3275 65.6538C52.5611 64.9731 53.2386 64.4332 53.9394 64.4097L124.418 63.5177C132.781 63.1422 141.822 56.3116 144.999 48.0021L149.017 37.4393C149.133 37.1576 149.18 36.8525 149.18 36.5473C149.18 36.383 149.157 36.2187 149.133 36.0544C144.601 15.4217 126.263 0 104.375 0C84.1912 0 67.0446 13.0979 60.9008 31.2894C56.9295 28.3083 51.8603 26.7122 46.3939 27.2521C36.6993 28.2145 28.9203 36.0544 27.9625 45.7957C27.7055 48.3308 27.9158 50.7485 28.4998 53.0488C12.6848 53.5183 0 66.5457 0 82.5308C0 83.9861 0.116802 85.3945 0.303686 86.8029C0.397128 87.4836 0.981141 87.9765 1.6586 87.9765L130.585 88C130.609 88 130.609 88 130.632 88C131.356 87.9765 132.01 87.4836 132.22 86.7559Z"
            fill="#F6821F"
          />
          <path
            d="M155.487 38.2139C154.833 38.2139 154.203 38.2374 153.549 38.2609C153.432 38.2609 153.338 38.2843 153.245 38.3313C152.918 38.4487 152.637 38.7303 152.544 39.0824L149.788 48.6124C148.596 52.6967 149.04 56.4993 151.026 59.2691C152.848 61.8277 155.885 63.33 159.576 63.5177L174.456 64.4097C174.9 64.4332 175.274 64.6444 175.507 64.9965C175.764 65.3486 175.811 65.8181 175.671 66.2641C175.437 66.9448 174.76 67.4847 174.059 67.5081L158.594 68.4001C150.208 68.7991 141.144 75.6063 137.967 83.9157L136.846 86.8498C136.636 87.3897 137.033 87.953 137.57 87.9765C137.593 87.9765 137.593 87.9765 137.617 87.9765H190.855C191.486 87.9765 192.047 87.554 192.234 86.9437C193.168 83.634 193.659 80.16 193.659 76.5452C193.659 55.3961 176.559 38.2139 155.487 38.2139Z"
            fill="#FBAD41"
          />
          <path
            d="M252.363 55.4196H261.17V79.6202H276.588V87.3662H252.363V55.4196Z"
            fill="#222222"
          />
          <path
            d="M285.722 71.4751V71.3812C285.722 62.2032 293.081 54.7623 302.892 54.7623C312.704 54.7623 319.969 62.1094 319.969 71.2873V71.3812C319.969 80.5591 312.61 88 302.799 88C292.987 88 285.722 80.653 285.722 71.4751ZM310.975 71.4751V71.3812C310.975 66.7805 307.658 62.7431 302.799 62.7431C297.987 62.7431 294.763 66.6631 294.763 71.2873V71.3812C294.763 75.9819 298.08 80.0192 302.892 80.0192C307.751 80.0192 310.975 76.0992 310.975 71.4751Z"
            fill="#222222"
          />
          <path
            d="M330.761 73.3529V55.4196H339.708V73.1886C339.708 77.7893 342.021 79.9957 345.572 79.9957C349.123 79.9957 351.435 77.9066 351.435 73.4233V55.4431H360.382V73.1651C360.382 83.4932 354.519 88 345.478 88C336.438 87.9531 330.761 83.3524 330.761 73.3529Z"
            fill="#222222"
          />
          <path
            d="M373.885 55.4196H386.149C397.502 55.4196 404.09 61.992 404.09 71.2169V71.3108C404.09 80.5356 397.409 87.3897 385.962 87.3897H373.885V55.4196ZM386.289 79.5263C391.569 79.5263 395.049 76.6156 395.049 71.4516V71.3577C395.049 66.2406 391.545 63.283 386.289 63.283H382.691V79.5263H386.289Z"
            fill="#222222"
          />
          <path
            d="M416.891 55.4196H442.354V63.1656H425.722V68.6114H440.766V75.9584H425.722V87.3662H416.891V55.4196Z"
            fill="#222222"
          />
          <path
            d="M454.619 55.4196H463.426V79.6202H478.82V87.3662H454.619V55.4196Z"
            fill="#222222"
          />
          <path
            d="M501.877 55.1848H510.38L523.929 87.3662H514.468L512.156 81.6623H499.891L497.625 87.3662H488.351L501.877 55.1848ZM509.586 74.7612L506.035 65.6772L502.438 74.7612H509.586Z"
            fill="#222222"
          />
          <path
            d="M535.259 55.4196H550.303C555.162 55.4196 558.526 56.7106 560.652 58.8936C562.521 60.7245 563.479 63.1891 563.479 66.3345V66.4284C563.479 71.3108 560.886 74.55 556.938 76.2401L564.53 87.3897H554.345L547.944 77.7189H544.066V87.3897H535.259V55.4196ZM549.883 70.7474C552.873 70.7474 554.602 69.2921 554.602 66.9683V66.8744C554.602 64.3628 552.779 63.0952 549.836 63.0952H544.066V70.7709H549.883V70.7474Z"
            fill="#222222"
          />
          <path
            d="M576.187 55.4196H601.766V62.9544H584.923V67.7898H600.178V74.7613H584.923V79.8314H602V87.3662H576.187V55.4196Z"
            fill="#222222"
          />
          <path
            d="M233.068 75.2307C231.83 78.024 229.237 80.0192 225.779 80.0192C220.967 80.0192 217.65 76.0053 217.65 71.3812V71.2873C217.65 66.6866 220.873 62.7431 225.686 62.7431C229.307 62.7431 232.087 64.9965 233.255 68.048H242.552C241.057 60.4428 234.423 54.7623 225.779 54.7623C215.968 54.7623 208.609 62.2032 208.609 71.3812V71.4751C208.609 80.653 215.874 88 225.686 88C234.072 88 240.636 82.5308 242.365 75.2073H233.068V75.2307Z"
            fill="#222222"
          />
        </svg>
      );
    case 'mark':
      return (
        <svg
          width="89"
          height="40"
          viewBox="0 0 89 40"
          fill="none"
          style={{ width: width || '100%', height: height || 'auto' }}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M60.3899 39.4384L60.8428 37.8735C61.3819 36.0118 61.1813 34.291 60.2764 33.0267C59.4447 31.8622 58.0581 31.1765 56.374 31.0967L24.4766 30.6927C24.3776 30.6906 24.2806 30.6653 24.1932 30.6188C24.1058 30.5724 24.0305 30.5061 23.9734 30.4253C23.9181 30.3414 23.8832 30.2457 23.8713 30.1459C23.8595 30.0461 23.871 29.9449 23.9051 29.8503C23.96 29.694 24.0595 29.5572 24.1911 29.4567C24.3228 29.3562 24.481 29.2964 24.6462 29.2846L56.8398 28.8763C60.658 28.7009 64.7926 25.6022 66.2402 21.8235L68.0768 17.0262C68.1259 16.896 68.1503 16.7578 68.1486 16.6186C68.1489 16.5423 68.1412 16.4662 68.1256 16.3915C66.0404 7.01117 57.6736 0 47.6686 0C38.45 0 30.6208 5.95022 27.8175 14.2207C25.9202 12.7966 23.5548 12.1419 21.1952 12.3878C16.7725 12.827 13.2166 16.3879 12.7788 20.8114C12.6661 21.9159 12.7483 23.0317 13.0218 24.1078C5.79424 24.3184 0 30.2377 0 37.5141C0.000652666 38.1637 0.0487002 38.8123 0.14376 39.4549C0.164346 39.6035 0.237829 39.7396 0.350721 39.8384C0.463613 39.9372 0.608339 39.9919 0.758333 39.9926L59.6467 39.9997C59.6522 40.0001 59.6577 40.0001 59.6632 39.9997C59.8284 39.996 59.988 39.9394 60.1188 39.8384C60.2495 39.7374 60.3446 39.5972 60.3899 39.4384Z"
            fill="#F6821F"
          />
          <path
            d="M71.0174 17.3741C70.7212 17.3741 70.4268 17.3815 70.134 17.3964C70.0868 17.3998 70.0403 17.4099 69.996 17.4266C69.919 17.4528 69.8496 17.4974 69.7937 17.5563C69.7377 17.6153 69.6969 17.6869 69.6747 17.7651L68.4203 22.0973C67.8812 23.959 68.0818 25.6784 68.986 26.9427C69.8184 28.1086 71.205 28.7929 72.8891 28.8727L79.6882 29.281C79.784 29.2841 79.8777 29.3096 79.9619 29.3552C80.0461 29.4009 80.1186 29.4656 80.1734 29.5441C80.2299 29.6282 80.2658 29.7243 80.2783 29.8248C80.2907 29.9253 80.2795 30.0274 80.2453 30.1227C80.1904 30.2787 80.0912 30.4153 79.9598 30.5158C79.8284 30.6163 79.6706 30.6763 79.5057 30.6884L72.4399 31.0967C68.6044 31.2735 64.4698 34.3708 63.0236 38.1495L62.5133 39.4836C62.4919 39.5394 62.4841 39.5993 62.4905 39.6587C62.4968 39.718 62.5172 39.775 62.5499 39.8249C62.5827 39.8748 62.6268 39.9162 62.6786 39.9457C62.7305 39.9753 62.7886 39.992 62.8482 39.9947C62.8547 39.9947 62.8604 39.9947 62.8669 39.9947H87.176C87.3172 39.9958 87.4549 39.9507 87.5681 39.8662C87.6813 39.7817 87.7637 39.6625 87.8028 39.5268C88.234 37.99 88.4517 36.4011 88.4497 34.805C88.4504 25.1788 80.645 17.3741 71.0174 17.3741Z"
            fill="#FBAD41"
          />
        </svg>
      );
    case 'vertical':
      return (
        <svg
          width="602"
          height="203"
          viewBox="0 0 602 203"
          fill="none"
          style={{ width: width || '100%', height: height || 'auto' }}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M510.338 128.167L511.809 123.081C513.562 117.031 512.91 111.438 509.969 107.329C507.262 103.544 502.752 101.315 497.274 101.056L393.528 99.7432C393.206 99.7369 392.89 99.655 392.606 99.504C392.321 99.353 392.077 99.1373 391.891 98.8742C391.71 98.6023 391.594 98.2918 391.553 97.9674C391.513 97.6429 391.549 97.3135 391.658 97.0053C391.836 96.4971 392.159 96.0521 392.587 95.7254C393.016 95.3987 393.53 95.2045 394.068 95.1668L498.775 93.8398C511.196 93.2698 524.644 83.1988 529.352 70.9178L535.323 55.3265C535.568 54.6672 535.624 53.9529 535.485 53.2637C528.705 22.7889 501.487 0 468.944 0C438.96 0 413.501 19.3385 404.369 46.2179C398.198 41.5895 390.504 39.4618 382.83 40.2608C368.445 41.6882 356.879 53.2613 355.455 67.6379C355.09 71.2277 355.357 74.8538 356.246 78.3513C332.747 79.0358 313.902 98.2738 313.902 121.922C313.905 124.033 314.061 126.141 314.369 128.23C314.437 128.712 314.676 129.154 315.043 129.475C315.41 129.796 315.881 129.974 316.368 129.977L507.902 130.001C507.92 130.002 507.938 130.002 507.956 130.001C508.498 129.991 509.022 129.808 509.451 129.478C509.88 129.147 510.191 128.688 510.338 128.167Z"
            fill="#F6821F"
          />
          <path
            d="M544.899 56.4571C543.938 56.4571 542.98 56.4813 542.026 56.5295C541.872 56.5405 541.721 56.5736 541.577 56.6277C541.327 56.7131 541.101 56.8578 540.919 57.0494C540.737 57.241 540.604 57.4739 540.532 57.728L536.453 71.8078C534.699 77.8584 535.351 83.4464 538.295 87.5556C541 91.3448 545.51 93.5688 550.987 93.8281L573.104 95.155C573.415 95.1653 573.719 95.2479 573.993 95.3963C574.266 95.5448 574.501 95.7549 574.679 96.0101C574.863 96.2834 574.98 96.5959 575.02 96.9225C575.061 97.2492 575.024 97.5808 574.913 97.8906C574.735 98.3978 574.412 98.842 573.985 99.1686C573.558 99.4951 573.044 99.69 572.508 99.7292L549.528 101.056C537.051 101.631 523.606 111.697 518.902 123.978L517.242 128.314C517.173 128.495 517.147 128.69 517.168 128.883C517.189 129.076 517.255 129.262 517.362 129.424C517.469 129.586 517.612 129.721 517.781 129.817C517.95 129.912 518.14 129.967 518.334 129.975C518.355 129.975 518.374 129.975 518.395 129.975H597.467C597.927 129.979 598.375 129.833 598.744 129.558C599.112 129.283 599.381 128.896 599.508 128.454C600.909 123.459 601.617 118.296 601.612 113.108C601.603 81.8228 576.215 56.4571 544.899 56.4571Z"
            fill="#FBAD41"
          />
          <path
            d="M66.9595 153.39H80.4491V190.214H104.022V202.024H66.9595V153.39Z"
            fill="#222222"
          />
          <path
            d="M117.993 177.847V177.709C117.993 163.744 129.257 152.416 144.276 152.416C159.295 152.416 170.418 163.604 170.418 177.569V177.709C170.418 191.674 159.152 202.995 144.138 202.995C129.124 202.995 117.993 191.812 117.993 177.847ZM156.65 177.847V177.709C156.65 170.701 151.577 164.575 144.138 164.575C136.767 164.575 131.829 170.551 131.829 177.569V177.709C131.829 184.718 136.905 190.841 144.276 190.841C151.715 190.841 156.65 184.865 156.65 177.847Z"
            fill="#222222"
          />
          <path
            d="M186.928 180.695V153.388H200.626V180.419C200.626 187.427 204.173 190.77 209.594 190.77C215.016 190.77 218.562 187.575 218.562 180.765V153.388H232.262V180.34C232.262 196.043 223.294 202.918 209.456 202.918C195.618 202.918 186.928 195.91 186.928 180.688"
            fill="#222222"
          />
          <path
            d="M252.906 153.392H271.679C289.064 153.392 299.145 163.396 299.145 177.429V177.571C299.145 191.602 288.924 202.026 271.403 202.026H252.906V153.392ZM271.89 190.07C279.958 190.07 285.307 185.631 285.307 177.77V177.632C285.307 169.853 279.958 165.335 271.89 165.335H266.396V190.074L271.89 190.07Z"
            fill="#222222"
          />
          <path
            d="M318.75 153.39H357.686V165.204H332.24V173.469H355.254V184.654H332.24V202.024H318.75V153.39Z"
            fill="#222222"
          />
          <path
            d="M376.457 153.39H389.946V190.214H413.519V202.024H376.457V153.39Z"
            fill="#222222"
          />
          <path
            d="M448.768 153.042H461.766L482.489 202.024H468.027L464.478 193.338H445.703L442.229 202.024H428.045L448.768 153.042ZM460.586 182.849L455.162 169.021L449.668 182.849H460.586Z"
            fill="#222222"
          />
          <path
            d="M499.865 153.388H522.879C530.323 153.388 535.464 155.336 538.732 158.67C541.587 161.45 543.045 165.211 543.045 169.998V170.135C543.045 177.567 539.071 182.501 533.032 185.07L544.645 202.026H529.065L519.262 187.294H513.354V202.026H499.865V153.388ZM522.255 176.735C526.844 176.735 529.488 174.511 529.488 170.967V170.829C529.488 167.007 526.706 165.064 522.182 165.064H513.354V176.744L522.255 176.735Z"
            fill="#222222"
          />
          <path
            d="M562.508 153.39H601.652V164.856H575.86V172.217H599.223V182.849H575.86V190.562H602V202.024H562.508V153.39Z"
            fill="#222222"
          />
          <path
            d="M37.425 183.547C35.536 187.815 31.5615 190.838 26.2802 190.838C18.9065 190.838 13.8333 184.724 13.8333 177.707V177.567C13.8333 170.558 18.7686 164.573 26.14 164.573C31.6971 164.573 35.9287 167.989 37.7149 172.635H51.9339C49.6568 161.06 39.4776 152.418 26.2802 152.418C11.2593 152.418 0 163.753 0 177.707V177.845C0 191.81 11.1214 203 26.14 203C38.9867 203 49.0279 194.688 51.6768 183.549L37.425 183.547Z"
            fill="#222222"
          />
        </svg>
      );
    case 'custom':
      return (
        <img
          className="top-nav__logo top-nav__logo--dark"
          src={customHeaderLogoUrl}
          alt="Cloudflare"
          style={{ width: '180px' }}
        />
      );
    default:
      return null;
  }
};
