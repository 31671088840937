import * as React from 'react';
import { usePsaBanner } from '../../common/hooks/usePsaBanner';
import { MarkdownBlock } from '../common/MarkdownBlock';
import { Icon } from '../common/Icon';
import { A, P, Section, Container, Button } from '../common/Elements';

export const PsaBanner: React.FC = props => {
  const [psaBanner, closeBanner] = usePsaBanner();

  return (
    <>
      {psaBanner && psaBanner.isOpen && (
        <Section
          backgroundColor="blue0"
          color="white"
          display="flex"
          justifyContent="around"
          className="promo-banner"
        >
          <Container display="flex" justifyContent="center">
            <MarkdownBlock
              display="flex"
              flex="auto"
              justifyContent="center"
              source={psaBanner.text}
              renderers={{
                paragraph: ({ children }) => (
                  <P margin={1} color="white" className="body-2">
                    {children}
                  </P>
                ),
                link: ({ children, ...props }) => (
                  <A
                    {...props}
                    display="inline-flex"
                    alignItems="center"
                    hovered={{ color: 'blue5' }}
                    color="white"
                    fontWeight={6}
                  >
                    {children} <Icon className="ml1" type="chevron" />
                  </A>
                ),
              }}
            />
            <Button paddingLeft={2} lineHeight={1} onClick={closeBanner}>
              <Icon type="x" fill="white" width={2} height={2} />
            </Button>
          </Container>
        </Section>
      )}
    </>
  );
};
