import React from 'react';
// import { useStaticStrings } from '../../../../common/hooks/useStaticStrings';
import { i18nPhoneNumberMapping } from '../../../../common/util/i18n-phone-mapping';
import { MaybeRedwood } from '../../../../config';
import { ContentfulNavigation } from '../../../../contentful/content-types/navigation';
import { A, Div, Nav, Span } from '../../../common/Elements';
import { LocaleLink } from '../../../common/links/LocaleLink';
import Text from '../../../common/Text/Text';
import { trackNavigationData } from '../utils/navigation.tracking';
import { LocaleDropdown } from './Dropdowns/LocaleDropdown';
import { UtilityNavItem } from './Dropdowns/UtilityNavItem';

interface TopnavProps {
  utilityNav: ContentfulNavigation;
}

export const TopNav: React.FC<TopnavProps> = ({ utilityNav }) => {
  const [countryCode, setCountryCode] = React.useState('US');
  // const staticStrings = useStaticStrings();

  React.useEffect(() => {
    const redwood = (window as MaybeRedwood).redwood;
    setCountryCode(redwood?.country || countryCode);
  }, []);

  const salesPhoneNumber = i18nPhoneNumberMapping[countryCode] || i18nPhoneNumberMapping['i18n'];

  const utilityNavigationItems = utilityNav?.navigationItems;

  return (
    <Div className="topnav" display="flex" lineHeight={0} alignItems="center" justifyContent="end">
      <Nav lineHeight={0} display="flex" alignItems="center">
        <Span marginRight={1} display="flex" alignItems="center">
          <LocaleLink
            display="block"
            className="newNav-link"
            to="/plans/enterprise/contact"
            onClick={() =>
              trackNavigationData({
                element: 'link',
                label: `utilitynav|Sales`,
              })
            }
          >
            <Text variant="body3" tag="Span" color="gray0">
              Sales:&nbsp;
            </Text>
          </LocaleLink>
          <LocaleLink
            data-i18n-phonenumber=""
            className="newNav-link"
            to={`tel:${salesPhoneNumber.number}`}
            onClick={() =>
              trackNavigationData({
                element: 'link',
                label: `utilitynav|${salesPhoneNumber.displayedNumber}:[tel:${salesPhoneNumber.number}]`,
              })
            }
          >
            <Text variant="body3" tag="Span" color="gray0">
              {salesPhoneNumber.displayedNumber}
            </Text>
          </LocaleLink>
          <noscript>
            <a
              className="newNav-link"
              href={`tel:${salesPhoneNumber.number}`}
              onClick={() =>
                trackNavigationData({
                  element: 'link',
                  label: `utilitynav|${salesPhoneNumber.displayedNumber}:[tel:${salesPhoneNumber.number}]`,
                })
              }
            >
              {salesPhoneNumber.displayedNumber}
            </a>
          </noscript>
        </Span>
        <Text variant="body3" color="black">
          |
        </Text>
        {utilityNavigationItems.map(item => (
          <UtilityNavItem item={item} key={item.contentfulId} />
        ))}
        <LocaleDropdown />
      </Nav>
    </Div>
  );
};
