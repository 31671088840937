import classNames from 'classnames';
import { Link } from 'gatsby';
import React, { useState } from 'react';
import { langCodeList } from '.';
import { useTranslations } from '../../../common/hooks/useTranslations';
import { defaultLocale, stripLocale } from '../../../common/util/locales';
import Dropdown from '../../common/Dropdown';
import { LocaleLink } from '../../common/links/LocaleLink';
import { persistLangToLocalStorage } from './header';
import { UtilityTopNavProps } from './utility-topnav';

const defaultURL = {
  origin: '',
  pathname: '/',
  protocol: 'https',
};

const I18nTopnav: React.FC<UtilityTopNavProps> = ({ salesNumber, locale, pathname }) => {
  const translate = useTranslations();
  return (
    <nav className="top-nav__upper-bar top-nav__items">
      <div className="top-nav__item-list top-nav__item-list--right">
        <span>
          <a
            className="bold"
            href="https://support.cloudflare.com/"
            data-tracking-category="mainnav"
            data-tracking-action="click"
            data-tracking-label="Support"
          >
            {translate('Support')}
          </a>
        </span>
        <span className="header-section-divider">|</span>
        <span>
          <LocaleLink to="/plans/enterprise/contact">{translate('sales_with_colon')} </LocaleLink>
        </span>
        <a data-i18n-phonenumber="" href={`tel:${salesNumber.number}`}>
          {salesNumber.displayedNumber}
        </a>
        <noscript>
          <a href={`tel:${salesNumber.number}`}> {salesNumber.displayedNumber}</a>
        </noscript>
        <span className="header-section-divider">|</span>

        <Dropdown
          renderToggle={(isOpen, toggle) => (
            <a
              className="header-language-picker js-header-language-picker"
              data-tooltipped=""
              onClick={toggle}
            >
              <span className="header-language-picker__globe-icon"></span>
              {langCodeList[locale.toLocaleLowerCase()].value}{' '}
              <span className="header-language-picker__caret-icon">{isOpen ? '▼' : '▶'}</span>
            </a>
          )}
          renderDropdownContent={isopen => (
            <div
              id="js-header-language-picker-dropdown"
              className="js-header-language-picker-dropdown"
            >
              <ul className="marketing-ul marketing-ul--no-bullets marketing-ul--no-margins-li header-language-picker-dropdown">
                {Object.keys(langCodeList).map((lang, index) => {
                  return (
                    <li
                      key={`langdropdown-${lang}`}
                      className={`${classNames({
                        'header-language-picker-dropdown__active':
                          lang === locale.toLocaleLowerCase(),
                      })} f2`}
                      onClick={persistLangToLocalStorage}
                    >
                      <Link
                        to={
                          lang === defaultLocale.toLocaleLowerCase()
                            ? `${stripLocale(pathname)}`
                            : `/${lang}${stripLocale(pathname)}`
                        }
                        data-tracking-category="topnav"
                        data-tracking-action="click"
                        data-tracking-label="en"
                      >
                        {langCodeList[lang].dropdownTitle}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        />
      </div>
    </nav>
  );
};

export default I18nTopnav;
