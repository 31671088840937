import React from 'react';
import { BaseIcon } from './BaseIcon';
import { colorMap, IconProps } from './icon.interface';

export const CFChevronRight: React.FC<IconProps> = ({ size, color, className }) => (
  <BaseIcon className={className} size={size}>
    <path
      d="M19.96 58.0141L17.132 55.1861L40.318 32.0001L17.132 8.81408L19.96 5.98608L45.975 32.0001L19.96 58.0141Z"
      fill={colorMap[color]}
    />
  </BaseIcon>
);
