import React, { Reducer } from 'react';
import { MenuModal } from './components/MenuModal';
import {
  initialModalState,
  menuModalReducer,
  ModalAction,
  ModalState,
} from './reducers/modal.reducer';
import { HeaderContext } from './header.context';
import { MainHeader } from './MainHeader';
import { Header as HTMLHeader } from '../../common/Elements';
import { ContentfulNavigation } from '../../../contentful/content-types/navigation';

interface HeaderProps {
  navData: ContentfulNavigation;
  utilityNav: ContentfulNavigation;
  redwoodRightNavData: ContentfulNavigation;
  customHeaderLogoUrl?: string | undefined;
}

const Header: React.FC<HeaderProps> = ({
  navData,
  utilityNav,
  redwoodRightNavData,
  customHeaderLogoUrl,
}) => {
  const [modal, dispatchModal] = React.useReducer<Reducer<ModalState, ModalAction>>(
    menuModalReducer,
    initialModalState
  );

  return (
    <HeaderContext.Provider value={{ modal, dispatch: dispatchModal }}>
      <HTMLHeader id="modal-topnav" zIndex={5} position="sticky" className="top-0">
        <MenuModal rightNavData={redwoodRightNavData} utilityNav={utilityNav} navData={navData} />
        <MainHeader
          rightNavData={redwoodRightNavData}
          utilityNav={utilityNav}
          navData={navData}
          customHeaderLogoUrl={customHeaderLogoUrl}
        />
      </HTMLHeader>
    </HeaderContext.Provider>
  );
};

export default Header;
