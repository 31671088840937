import { GlobalVariables } from '../fragments/MRKConfig';
import { Locale } from './locales';

/**
 * Interpolate globals into text, localizing the numbers along the way
 * @param text The text to interpolate
 * @param locale The locale to use for number formatting
 * @param globals The global values to use for interpolation
 */
export function interpolateGlobalVariables(
  text: string,
  locale: Locale,
  globals: GlobalVariables['json_values']
) {
  const localizedNumberGlobals: { [K in keyof GlobalVariables]: string } = Object.keys(
    globals
  ).reduce((result, key) => {
    const value = globals[key as keyof GlobalVariables['json_values']];

    if (typeof value === 'number') {
      result[key] = value.toLocaleString(locale);
    } else {
      result[key] = value;
    }

    return result;
  }, {} as any);

  return interpolateObject(text, localizedNumberGlobals);
}

/**
 * Interpolates an object into a string
 * @param text The string to interpolate object into
 * @param obj The Key/Val pair to interpolate
 */
export function interpolateObject(text: string, obj: any) {
  return Object.keys(obj).reduce(
    (result, key) => result.replace(new RegExp(`%{${key}}`, 'g'), obj[key]),
    text
  );
}
