import React from 'react';
import { Row } from '../../common/Elements';
import { MainFooter } from '../footer/footer.interfaces';
import FooterColumn from './footer-column';
import { ColumnData } from './footer.interfaces';

interface FooterColumnProps {
  footerData: MainFooter;
}

const FooterColumns: React.FC<FooterColumnProps> = ({ footerData }) => {
  const columnData: ColumnData[] = [];
  const columnCount = 6;
  for (let i = 1; i <= columnCount; i++) {
    columnData.push({
      title: footerData[`column${i}Title`],
      items: footerData[`column${i}`],
    });
  }
  return (
    <Row>
      {columnData.map((column, columnIndex) => (
        <FooterColumn
          contactField={footerData.contactField}
          key={columnIndex}
          columnIndex={columnIndex}
          column={column}
        />
      ))}
    </Row>
  );
};

export default FooterColumns;
