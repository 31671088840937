import React from 'react';
import { initialTabState, TabAction, TabState } from './reducers/tabmenu.reducer';

export interface TabContext {
  tab: TabState;
  dispatch: React.Dispatch<TabAction>;
}

export const TabContext = React.createContext<TabContext>({
  tab: initialTabState,
  dispatch: () => {},
});
export const useTabContext = (): TabContext => React.useContext(TabContext);
