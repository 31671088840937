import { FontIndex, FontWeightIndex, LineHeightIndex, TextVariants } from '../Elements/types';

interface TextStyleProps {
  fontSize: FontIndex | [FontIndex, FontIndex] | [FontIndex, FontIndex, FontIndex, FontIndex];
  fontWeight:
    | FontWeightIndex
    | [FontWeightIndex, FontWeightIndex]
    | [FontWeightIndex, FontWeightIndex, FontWeightIndex, FontWeightIndex];
  lineHeight:
    | LineHeightIndex
    | [LineHeightIndex, LineHeightIndex]
    | [LineHeightIndex, LineHeightIndex, LineHeightIndex, LineHeightIndex];
}

type ITexVariantMap = {
  [key in TextVariants]: TextStyleProps;
};

export const TextVariantMap: ITexVariantMap = {
  headline1: {
    fontSize: [6, 6, 6, 8],
    fontWeight: 6,
    lineHeight: 2,
  },
  headline2: {
    fontSize: [6, 6, 6, 7],
    fontWeight: 6,
    lineHeight: 2,
  },
  headline3: {
    fontSize: 6,
    fontWeight: 6,
    lineHeight: 4,
  },
  headline4: {
    fontSize: 5,
    fontWeight: 6,
    lineHeight: 4,
  },
  headline5: {
    fontSize: 4,
    fontWeight: 6,
    lineHeight: 5,
  },
  'small-caps': {
    fontSize: 2,
    fontWeight: 6,
    lineHeight: 6,
  },
  body1: {
    fontSize: 3,
    fontWeight: 4,
    lineHeight: 6,
  },
  'body1-bold': {
    fontSize: 3,
    fontWeight: 6,
    lineHeight: 6,
  },
  body2: {
    fontSize: 2,
    fontWeight: 4,
    lineHeight: 6,
  },
  'body2-bold': {
    fontSize: 2,
    fontWeight: 6,
    lineHeight: 6,
  },
  body3: {
    fontSize: 1,
    fontWeight: 4,
    lineHeight: 6,
  },
  'body3-bold': {
    fontSize: 1,
    fontWeight: 6,
    lineHeight: 6,
  },
  stat: {
    fontSize: 9,
    fontWeight: 4,
    lineHeight: 1,
  },
  quote1: {
    fontSize: [5, 5, 5, 6],
    fontWeight: 4,
    lineHeight: 4,
  },
  quote2: {
    fontSize: 5,
    fontWeight: 4,
    lineHeight: 4,
  },
  learnmore: {
    fontSize: 3,
    fontWeight: 7,
    lineHeight: 6,
  },
  caption: {
    fontSize: 1,
    fontWeight: 6,
    lineHeight: 6,
  },
};
