import React from 'react';
import { BaseIcon } from './BaseIcon';
import { colorMap, IconProps } from './icon.interface';

export const CFInfo: React.FC<IconProps> = ({ size, color, className }) => (
  <BaseIcon className={className} size={size}>
    <path
      d="M32 6C26.8577 6 21.8309 7.52487 17.5552 10.3818C13.2795 13.2387 9.94702 17.2994 7.97914 22.0502C6.01127 26.8011 5.49638 32.0288 6.49959 37.0723C7.50281 42.1159 9.97907 46.7486 13.6152 50.3848C17.2514 54.0209 21.8842 56.4972 26.9277 57.5004C31.9712 58.5036 37.1989 57.9887 41.9498 56.0209C46.7007 54.053 50.7613 50.7205 53.6182 46.4448C56.4751 42.1691 58 37.1423 58 32C58 25.1044 55.2607 18.4912 50.3848 13.6152C45.5088 8.73928 38.8956 6 32 6ZM32 54C27.6488 54 23.3953 52.7097 19.7775 50.2923C16.1596 47.8749 13.3398 44.439 11.6747 40.419C10.0095 36.3991 9.57386 31.9756 10.4227 27.708C11.2716 23.4404 13.3669 19.5204 16.4437 16.4437C19.5204 13.3669 23.4404 11.2716 27.708 10.4227C31.9756 9.57385 36.3991 10.0095 40.419 11.6747C44.439 13.3398 47.875 16.1596 50.2923 19.7775C52.7097 23.3953 54 27.6488 54 32C54 37.8348 51.6822 43.4305 47.5564 47.5564C43.4306 51.6822 37.8348 54 32 54Z"
      fill={colorMap[color]}
    />
    <path
      d="M34.29 25.01H26.43V29.01H30.29V44.26H25.59V48.26H38.99V44.26H34.29V25.01Z"
      fill={colorMap[color]}
    />
    <path d="M33.9599 16.13H28.9399V21.15H33.9599V16.13Z" fill={colorMap[color]} />
  </BaseIcon>
);
