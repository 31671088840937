import {
  DurationBadgeLevelsObject,
  PlansObject,
} from '../../components/welcome-center/interfaces/welcome-center.interface';
import { GlobalStrings } from '../fragments/MRKConfig';
import { useStaticStrings } from './useStaticStrings';

export type StaticString = GlobalStrings['json_values'];

export function useTranslations() {
  const staticStrings = useStaticStrings();

  return <T extends keyof StaticString>(text: T | string): StaticString[T] => {
    const key = text?.replace(/[\W_]/g, '_') as string;

    if (!isGlobalStringsKey(key, staticStrings)) {
      return text as any;
    }

    return staticStrings.json_values[key] as any;
  };
}

function isGlobalStringsKey(key: string, globalStrings: GlobalStrings): key is keyof StaticString {
  return key in globalStrings.json_values;
}
