import React from 'react';
import { Button, Span } from '../../../common/Elements';
import Text from '../../../common/Text/Text';
import { MODAL_TYPES } from '../reducers/modal.reducer';
import { useHeaderContext } from '../header.context';
import { CFChevronRight } from '../../../common/CFIcons/CFChevronRight';
import { ContentfulNavigationItem } from '../../../../contentful/content-types/navigationitem';
import Media from 'react-media';
import { trackNavigationData } from '../utils/navigation.tracking';

interface Props {
  data: ContentfulNavigationItem;
}

export const MenuItem: React.FC<Props> = ({ data }) => {
  const [hovered, setHovered] = React.useState<boolean>(false);
  const { modal, dispatch } = useHeaderContext();

  const handleMenuClick = (data: ContentfulNavigationItem, isActive: boolean) => {
    if (isActive) {
      dispatch({
        type: MODAL_TYPES.SHOW,
        payload: {
          selectedMenu: data.contentfulId,
          modalData: data,
        },
      });
    } else {
      dispatch({
        type: MODAL_TYPES.HIDE,
      });
    }

    trackNavigationData({
      element: 'button',
      label: `mainnav|${data.name}`,
    });
  };

  const isActive = data.contentfulId === modal.modalProps.selectedMenu;

  return (
    <Media
      queries={{
        medium: '(min-width: 1165px) and (max-width: 1260px)',
        large: '(min-width: 1261px)',
      }}
      defaultMatches={{ large: true }}
    >
      {matches => (
        <Button
          onClick={() => handleMenuClick(data, isActive)}
          key={data.contentfulId}
          paddingVertical={[2, 2, 2, 0]}
          paddingHorizontal={0}
          marginRight={[0, 0, 0, matches.large ? 5 : matches.medium ? 4 : 3]}
          border={['bottom', 'bottom', 'bottom', 'none']}
          borderColor="gray2"
          width={['100%', '100%', '100%', 'auto']}
          className="pointer newNav-top-link"
          textAlign="left"
          wordBreak="keep-all"
          display="flex"
          justifyContent="between"
          alignItems="center"
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          tabIndex={0}
          backgroundColor="transparent"
        >
          <Text
            color={isActive ? 'blue1' : 'black'}
            border="bottom"
            borderColor={isActive ? 'blue1' : 'transparent'}
            borderWidth={2}
            heightPercentage={100}
            display="flex"
            alignItems="end"
            paddingBottom="4px"
            hovered={{
              color: 'bluehover',
              border: 'bottom',
              borderWidth: 2,
              borderColor: 'bluehover',
            }}
            tag="Span"
            variant="body2-bold"
          >
            {data.name}
          </Text>
          <Span lineHeight={0} display={['inline-flex', 'none', 'inline-flex', 'none']}>
            <CFChevronRight
              size={16}
              color={hovered ? 'bluehover' : isActive ? 'blue1' : 'black'}
            />
          </Span>
        </Button>
      )}
    </Media>
  );
};
