import { useState, useEffect } from 'react';
import { PsaBanner } from '../../components/layout/PsaBanner';
interface PsaBanner {
  id: number;
  text: string;
  isOpen: boolean;
}

declare global {
  interface Window {
    psaBanner: {
      id: number;
      text: string;
    };
  }
}

export function usePsaBanner(): [null | PsaBanner, () => void] {
  const [psaBanner, setPsaBanner] = useState<PsaBanner | null>(null);

  useEffect(() => {
    const psaBannerLocalStorage = localStorage.getItem('psaBanner');

    const parsedPsaBanner = JSON.parse(psaBannerLocalStorage || '{}');
    if (parsedPsaBanner.isPsaBannerClosed) {
      setPsaBanner({ ...window.psaBanner, isOpen: false });
      return;
    }

    if (typeof window.psaBanner === 'object') {
      setPsaBanner({ ...window.psaBanner, isOpen: true });
    }
  }, []);

  const closeBanner = () => {
    if (!psaBanner || typeof window.psaBanner !== 'object') {
      return;
    }

    setPsaBanner({ ...psaBanner, isOpen: false });
    const localStorageObj = {
      id: psaBanner.id,
      isPsaBannerClosed: true,
    };

    localStorage.setItem('psaBanner', JSON.stringify(localStorageObj));
  };
  return [psaBanner, closeBanner];
}
