import React from 'react';
import { BaseIcon } from './BaseIcon';
import { colorMap, IconProps } from './icon.interface';

export const CFAttention: React.FC<IconProps> = ({ size, color, className }) => (
  <BaseIcon className={className} size={size}>
    <path
      d="M33.77 7.80005H30.23L6.22998 53.17L7.99998 56.1H56L57.77 53.17L33.77 7.80005ZM11.31 52.1L32 13L52.69 52.1H11.31Z"
      fill={colorMap[color]}
    />
    <path d="M33.9199 26.52H29.9199V40.28H33.9199V26.52Z" fill={colorMap[color]} />
    <path d="M33.9199 42.91H29.9199V47.49H33.9199V42.91Z" fill={colorMap[color]} />
  </BaseIcon>
);
