import React from 'react';
import Media from 'react-media';
import { ContentfulNavigation } from '../../../../contentful/content-types/navigation';
import { CFXExit } from '../../../common/CFIcons/CFXExit';
import { Button, Div, Span } from '../../../common/Elements';
import { Icon } from '../../../common/Icon';
import { LocaleLink } from '../../../common/links/LocaleLink';
import { Logo } from '../../../common/Logo/Logo';
import Text from '../../../common/Text/Text';
import { useHeaderContext } from '../header.context';
import { MODAL_TYPES } from '../reducers/modal.reducer';
import { trackNavigationData } from '../utils/navigation.tracking';
import { LocaleDropdown } from './Dropdowns/LocaleDropdown';
import { UtilityNavItem } from './Dropdowns/UtilityNavItem';
import { NavMenu } from './NavMenu';
import { RightNav } from './RightNav';

interface MainnavProps {
  modalNav?: boolean;
  rightNavData: ContentfulNavigation;
  navData: ContentfulNavigation;
  utilityNav: ContentfulNavigation;
  customHeaderLogoUrl?: string | undefined;
}

export const MainNav: React.FC<MainnavProps> = ({
  modalNav,
  navData,
  utilityNav,
  rightNavData,
  customHeaderLogoUrl,
}) => {
  const { modal, dispatch } = useHeaderContext();

  const handleMenuClick = () => {
    dispatch({
      type: modal.open ? MODAL_TYPES.HIDE : MODAL_TYPES.SHOW,
    });
    trackNavigationData({
      element: 'button',
      label: 'mainnav|menutoggle',
    });
  };

  const displayNav = modalNav ? 'flex' : 'none';

  const userDropDown = utilityNav?.navigationItems?.find(
    item => item.contentfulId === '7BSYXeT710gmAyEbd0CNbd'
  );

  return (
    <Div
      justifyContent="between"
      alignItems="stretch"
      flexWrap={['wrap', 'wrap', 'wrap', 'nowrap']}
      display="flex"
    >
      <Media
        queries={{
          large: '(min-width: 1120px)',
        }}
        defaultMatches={{ large: true }}
      >
        {matches => (
          <Div display="flex" alignItems="center" marginRight={[0, 0, 0, matches.large ? 6 : 3]}>
            {modalNav ? (
              <Button
                display="flex"
                role="button"
                lineHeight={0}
                className="pointer newNav-logo"
                padding={0}
                backgroundColor="transparent"
                onClick={() => {
                  dispatch({ type: MODAL_TYPES.HIDE });
                  trackNavigationData({
                    element: 'button',
                    label: `mainnav|logo`,
                  });
                }}
              >
                <Span display={['none', 'none', 'none', 'block']}>
                  <Logo
                    height={matches.large ? '40px' : '22px'}
                    width="auto"
                    variant={customHeaderLogoUrl ? 'custom' : matches.large ? 'vertical' : 'mark'}
                    color="default"
                    customHeaderLogoUrl={customHeaderLogoUrl}
                  />
                </Span>
                <Span display={['block', 'block', 'block', 'none']}>
                  <Logo
                    height="22px"
                    width="auto"
                    variant={customHeaderLogoUrl ? 'custom' : 'mark'}
                    customHeaderLogoUrl={customHeaderLogoUrl}
                    color="default"
                  />
                </Span>
              </Button>
            ) : (
              <LocaleLink
                display="flex"
                lineHeight={0}
                className="newNav-logo"
                variant="anchor"
                to="/"
                onClick={() => {
                  trackNavigationData({
                    element: 'link',
                    label: `mainnav|logo`,
                  });
                }}
              >
                <Span display={['none', 'none', 'none', 'block']}>
                  <Logo
                    height={matches.large ? '40px' : '22px'}
                    width="auto"
                    variant={
                      customHeaderLogoUrl
                        ? 'custom'
                        : matches.large
                        ? 'vertical'
                        : matches.large
                        ? 'vertical'
                        : 'mark'
                    }
                    customHeaderLogoUrl={customHeaderLogoUrl}
                    color="default"
                  />
                </Span>
                <Span display={['block', 'block', 'block', 'none']}>
                  <Logo
                    height="22px"
                    width="auto"
                    variant={
                      customHeaderLogoUrl
                        ? 'custom'
                        : matches.large
                        ? 'vertical'
                        : matches.large
                        ? 'vertical'
                        : 'horizontal'
                    }
                    customHeaderLogoUrl={customHeaderLogoUrl}
                    color="default"
                  />
                </Span>
              </LocaleLink>
            )}
          </Div>
        )}
      </Media>

      {/* Mobile Top Nav start */}
      <Div display={['flex', 'none', 'flex', 'none']} alignItems="center" justifyContent="end">
        {userDropDown && <UtilityNavItem hideDivider item={userDropDown} />}
        {modalNav && (
          <>
            <Text variant="body3" color="black">
              |
            </Text>
            <LocaleDropdown />
          </>
        )}
        <Button role="button" padding={0} backgroundColor="transparent" onClick={handleMenuClick}>
          <Span lineHeight={0} marginLeft={2}>
            {modalNav ? <CFXExit size={16} color="black" /> : <Icon type="nav-burger" />}
          </Span>
        </Button>
      </Div>
      {/* Mobile Top Nav End */}

      <Div
        display={[displayNav, displayNav, displayNav, 'flex']}
        width={['100%', '100%', '100%', 'auto']}
        alignItems="stretch"
        flex="auto"
      >
        <NavMenu rightNavData={rightNavData} utilityNav={utilityNav} navData={navData} />
      </Div>
      <Div display={['none', 'none', 'none', 'flex']} alignItems="end" justifyContent="between">
        <RightNav rightNavData={rightNavData} />
      </Div>
    </Div>
  );
};
