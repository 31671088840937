import React from 'react';
import { useStaticStrings } from '../../../common/hooks/useStaticStrings';
import { A, Div, Span } from '../../common/Elements';
import { Icon } from '../../common/Icon';
import { Copyright } from '../footer/footer.interfaces';
import { socialLinks } from './footer-constants';

interface FooterBottomProps {
  bottomLinks: Copyright[];
  copyrightYear: string;
}

const FooterBottom: React.FC<FooterBottomProps> = ({ copyrightYear, bottomLinks }) => {
  const staticStrings = useStaticStrings();
  return (
    <Div paddingVertical={5}>
      <Div display="flex" flexWrap="wrap" justifyContent={['start', 'start', 'start', 'between']}>
        <Div marginBottom={[3, 3, 3, 0]} lineHeight={1}>
          {socialLinks.map(link => (
            <A
              lineHeight={1}
              display="inline-block"
              marginRight={2}
              title={link.title}
              target="_blank"
              rel="noopener"
              key={link.title}
              href={link.url}
            >
              <Icon type={link.iconType} />
            </A>
          ))}
        </Div>
        <Div
          display="flex"
          flexWrap="wrap"
          alignItems="center"
          fontWeight={5}
          lineHeight="copy"
          fontSize={1}
        >
          <Span fontWeight={5} paddingRight={1} display="inline-flex">
            &copy; {copyrightYear} {staticStrings.json_values.Cloudflare_Inc}
          </Span>
          {bottomLinks.map(item => (
            <A
              border="left"
              display="inline-flex"
              color="white"
              lineHeight="solid"
              fontWeight={5}
              paddingHorizontal={1}
              key={`copy-right-${item.contentfulId}`}
              href={item.url}
              className={item.customClassName}
            >
              {item.title}
            </A>
          ))}
        </Div>
      </Div>
    </Div>
  );
};

export default FooterBottom;
