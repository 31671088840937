import React from 'react';
import { Container, Div, Row } from '../../common/Elements';
import { MainFooter } from '../footer/footer.interfaces';
import FooterBottom from './footer-bottom';
import FooterColumns from './footer-columns';

interface FooterProps {
  footerData: MainFooter;
}

const Footer: React.FC<FooterProps> = ({ footerData }) => {
  return (
    <Div color="white" backgroundColor="black">
      <Container>
        <Div paddingTop={[3, 3, 3, 7]} paddingBottom={[0, 0, 0, 5]}>
          <FooterColumns footerData={footerData} />
        </Div>
        <FooterBottom copyrightYear={footerData.copyrightYear} bottomLinks={footerData.copyRight} />
      </Container>
    </Div>
  );
};

export default Footer;
