import {
  CFAirplanePaper,
  CFAnalyticsBots,
  CFAnalyticsData,
  CFAnalyticsNetwork,
  CFAnalyticsPie,
  CFArrow,
  CFArrowExternal,
  CFArrowExternalBordered,
  CFArrowTwoWay,
  CFAttacker,
  CFBenefitsHealthCare,
  CFBenefitsPaidVacation,
  CFBenefitsParentalLeave,
  CFBenefitsReturnship,
  CFBenefitsSalary,
  CFBrowserVideo,
  CFCalendar,
  CFCaretDown,
  CFCaretLeft,
  CFCaretReorder,
  CFCaretRight,
  CFCaretUp,
  CFCaseStudy,
  CFCellTower,
  CFCertificate,
  CFCertificateManager,
  CFChevronLeft,
  CFCloudflareAccess,
  CFCloudflareApi,
  CFCloudflareBrowser,
  CFCloudflareGateway,
  CFCloudflarePages,
  CFCloudflareRadar,
  CFCloudflareRegistrar,
  CFCloudflareSpectrum,
  CFCloudflareStreamDelivery,
  CFCloudflareWarp,
  CFCloudflareWorkers,
  CFCloudHybrid,
  CFCloudInternet,
  CFCloudMulti,
  CFCloudUpload,
  CFCodeApi,
  CFCodeContentOptimization,
  CFCodeJs,
  CFCollapse,
  CFConnect,
  CFConnect2,
  CFConnect3,
  CFConnectIot,
  CFCopyDuplicate,
  CFCreditCard,
  CFDDOSAttack,
  CFDeviceDesktop,
  CFDeviceLaptop,
  CFDeviceMobile,
  CFDeviceTablet,
  CFDocumentationLogs,
  CFDocumentationRules,
  CFDocumentClipboard,
  CFDocumentList,
  CFDownload,
  CFDurableObjects,
  CFEaseOfUse,
  CFEdgeLogDelivery,
  CFEdit,
  CFEmail,
  CFExpand,
  CFEyeball,
  CFFaceHappy,
  CFFaceSad,
  CFFiltering,
  CFGeoKeyManager,
  CFHamburger,
  CFHamburgerWide,
  CFHealthCheck,
  CFHelpGiving,
  CFHelpQuestion,
  CFImage,
  CFIndustry,
  CFIndustryGaming,
  CFInnovationIntelligence,
  CFInnovationThinking,
  CFInternetBrowser,
  CFIpTruncation,
  CFKey,
  CFLeaderCrown,
  CFLearningCenterBlock,
  CFLink,
  CFLoading,
  CFLocationPin,
  CFLogoFacebook,
  CFLogoGithub,
  CFMediaPause,
  CFMediaPlay,
  CFMediaStop,
  CFMore,
  CFMoreOutlined,
  CFNetworkScale,
  CFNetworkVirtualBackbone,
  CFNoSecurityShield,
  CFNotification,
  CFNumericAdd,
  CFNumericMinus,
  CFNumericOne,
  CFNumericPlus,
  CFNumericSubtract,
  CFNumericThree,
  CFNumericTwo,
  CFOfficeBranch,
  CFOfficeHeadquaters,
  CFOpenDoor,
  CFOptimizationGear,
  CFOptimizationScale,
  CFOptimizationWeb,
  CFOrbit,
  CFPerformance,
  CFPerformanceAccelerationBolt,
  CFPerformanceAccelerationRocket,
  CFPerformanceArrowUp,
  CFPerformanceCloudSpeed,
  CFPerformanceIntelligentRouting,
  CFPerformanceRouting,
  CFPerformanceValidator,
  CFPerformanceWrench,
  CFPlatformApps,
  CFPower,
  CFPremiumSuccessOffering,
  CFPrice,
  CFPrinter,
  CFProcessFlow,
  CFProcessStack,
  CFPullRequest,
  CFPullRequestMerged,
  CFRefresh,
  CFRegionalServices,
  CFReliabilityDns,
  CFReliabilityDnsResolver,
  CFReliabilityLoadBalance,
  CFReliabilityTimer,
  CFRevert,
  CFRouter,
  CFSalelite,
  CFSearch,
  CFSecurityBots,
  CFSecurityCrawler,
  CFSecurityDDOSProtection,
  CFSecurityFingerprint,
  CFSecurityLock,
  CFSecurityProtection,
  CFSecuritySafe,
  CFSecurityScraping,
  CFSecurityWaf,
  CFSecurityZeroTrust,
  CFServerDatabase,
  CFServerDouble,
  CFServerOrigin,
  CFServerRows,
  CFServerStacked,
  CFShare,
  CFShopCart,
  CFShopCartNo,
  CFSpamEmail,
  CFStop,
  CFStopX,
  CFSupportChat,
  CFTarget,
  CFTargetRetarget,
  CFTime,
  CFTrafficAttack,
  CFTrafficLegit,
  CFUser,
  CFUserAddMember,
  CFUserMulti,
} from './loadable';

import { CFInternetGlobe } from './CFInternetGlobe';
import { CFInfo } from './CFInfo';
import { CFYesCheck } from './CFYesCheck';
import { CFChevronRight } from './CFChevronRight';
import { CFAttention } from '../../common/CFIcons/CFAttention';
import { CFXExit } from '../../common/CFIcons/CFXExit';
import { CFArrowBackward } from '../../common/CFIcons/CFArrowBackward';
import { CFCheck } from '../../common/CFIcons/CFCheck';

import { IconMap } from './icon.interface';

export const iconMap: IconMap = {
  'airplane-paper': CFAirplanePaper,
  'analytics-bots': CFAnalyticsBots,
  'analytics-data': CFAnalyticsData,
  'analytics-network': CFAnalyticsNetwork,
  'analytics-pie': CFAnalyticsPie,
  arrow: CFArrow,
  'arrow-backward': CFArrowBackward,
  'arrow-external-link-1': CFArrowExternal,
  'arrow-external-link-2': CFArrowExternalBordered,
  'arrow-twoway': CFArrowTwoWay,
  attacker: CFAttacker,
  attention: CFAttention,
  'benefits-healthcare': CFBenefitsHealthCare,
  'benefits-paid-vacation': CFBenefitsPaidVacation,
  'benefits-parental-leave': CFBenefitsParentalLeave,
  'benefits-returnship': CFBenefitsReturnship,
  'benefits-salary': CFBenefitsSalary,
  'browser-video': CFBrowserVideo,
  calendar: CFCalendar,
  'caret-down-1': CFCaretDown,
  'caret-left-1': CFCaretLeft,
  'caret-left-2': CFChevronLeft,
  'caret-reorder': CFCaretReorder,
  'caret-right-1': CFCaretRight,
  'caret-right-2': CFChevronRight,
  'caret-up-1': CFCaretUp,
  'case-study': CFCaseStudy,
  'cell-tower': CFCellTower,
  certificate: CFCertificate,
  'certificate-manager': CFCertificateManager,
  check: CFCheck,
  'cloud-hybrid': CFCloudHybrid,
  'cloud-internet': CFCloudInternet,
  'cloud-multi': CFCloudMulti,
  'cloud-upload': CFCloudUpload,
  'cloudflare-access': CFCloudflareAccess,
  'cloudflare-api': CFCloudflareApi,
  'cloudflare-browser': CFCloudflareBrowser,
  'cloudflare-gateway': CFCloudflareGateway,
  'cloudflare-pages': CFCloudflarePages,
  'cloudflare-radar': CFCloudflareRadar,
  'cloudflare-registrar': CFCloudflareRegistrar,
  'cloudflare-spectrum': CFCloudflareSpectrum,
  'cloudflare-stream-delivery': CFCloudflareStreamDelivery,
  'cloudflare-warp': CFCloudflareWarp,
  'cloudflare-workers': CFCloudflareWorkers,
  'code-api': CFCodeApi,
  'code-content-optimization': CFCodeContentOptimization,
  'code-js': CFCodeJs,
  collapse: CFCollapse,
  'connect-1': CFConnect,
  'connect-2': CFConnect2,
  'connect-3': CFConnect3,
  'connect-iot': CFConnectIot,
  'copy-duplicate': CFCopyDuplicate,
  'ddos-attack': CFDDOSAttack,
  'device-desktop': CFDeviceDesktop,
  'device-laptop': CFDeviceLaptop,
  'device-mobile': CFDeviceMobile,
  'device-tablet': CFDeviceTablet,
  'document-clipboard': CFDocumentClipboard,
  'document-list': CFDocumentList,
  'documentation-logs': CFDocumentationLogs,
  'documentation-rules': CFDocumentationRules,
  download: CFDownload,
  'durable-objects': CFDurableObjects,
  'ease-of-use': CFEaseOfUse,
  'edge-log-delivery': CFEdgeLogDelivery,
  edit: CFEdit,
  email: CFEmail,
  expand: CFExpand,
  eyeball: CFEyeball,
  'face-happy': CFFaceHappy,
  'face-sad': CFFaceSad,
  filtering: CFFiltering,
  'geo-key-manager': CFGeoKeyManager,
  'hamburger-1': CFHamburger,
  'hamburger-2': CFHamburgerWide,
  'health-check': CFHealthCheck,
  'help-giving': CFHelpGiving,
  'help-question': CFHelpQuestion,
  image: CFImage,
  industry: CFIndustry,
  'industry-gaming': CFIndustryGaming,
  info: CFInfo,
  'innovation-intelligence': CFInnovationIntelligence,
  'innovation-thinking': CFInnovationThinking,
  'internet-browser': CFInternetBrowser,
  'internet-globe': CFInternetGlobe,
  'ip-truncation': CFIpTruncation,
  key: CFKey,
  'leader-crown': CFLeaderCrown,
  'learning-center-block': CFLearningCenterBlock,
  link: CFLink,
  loading: CFLoading,
  'location-pin': CFLocationPin,
  'logo-facebook': CFLogoFacebook,
  'logo-github': CFLogoGithub,
  'media-pause': CFMediaPause,
  'media-play': CFMediaPlay,
  'media-stop': CFMediaStop,
  'more-1': CFMoreOutlined,
  'more-2': CFMore,
  'network-scale': CFNetworkScale,
  'network-virtual-backbone': CFNetworkVirtualBackbone,
  'no-security-shield': CFNoSecurityShield,
  'no-stop': CFStop,
  'no-stop-x': CFStopX,
  'notification-announcements': CFNotification,
  'numeric-1': CFNumericOne,
  'numeric-2': CFNumericTwo,
  'numeric-3': CFNumericThree,
  'numeric-add': CFNumericAdd,
  'numeric-minus': CFNumericMinus,
  'numeric-plus': CFNumericPlus,
  'numeric-subtract': CFNumericSubtract,
  'office-branch': CFOfficeBranch,
  'office-headquaters': CFOfficeHeadquaters,
  'open-door': CFOpenDoor,
  'optimization-gear': CFOptimizationGear,
  'optimization-scale': CFOptimizationScale,
  'optimization-web': CFOptimizationWeb,
  orbit: CFOrbit,
  'payments-credit-card': CFCreditCard,
  'performance-1': CFPerformance,
  'performance-acceleration-bolt': CFPerformanceAccelerationBolt,
  'performance-acceleration-rocket': CFPerformanceAccelerationRocket,
  'performance-arrow-up': CFPerformanceArrowUp,
  'performance-cloud-speed': CFPerformanceCloudSpeed,
  'performance-intelligent-routing': CFPerformanceIntelligentRouting,
  'performance-routing': CFPerformanceRouting,
  'performance-validator': CFPerformanceValidator,
  'performance-wrench': CFPerformanceWrench,
  'platform-apps': CFPlatformApps,
  power: CFPower,
  'premium-success-offering': CFPremiumSuccessOffering,
  price: CFPrice,
  printer: CFPrinter,
  'process-flow': CFProcessFlow,
  'process-stack': CFProcessStack,
  'pull-request': CFPullRequest,
  'pull-request-merged': CFPullRequestMerged,
  refresh: CFRefresh,
  'regional-services': CFRegionalServices,
  'reliability-dns': CFReliabilityDns,
  'reliability-dns-resolver': CFReliabilityDnsResolver,
  'reliability-load-balancer': CFReliabilityLoadBalance,
  'reliability-timer': CFReliabilityTimer,
  revert: CFRevert,
  router: CFRouter,
  satelite: CFSalelite,
  search: CFSearch,
  'security-bots': CFSecurityBots,
  'security-crawler': CFSecurityCrawler,
  'security-fingerprint-privacy': CFSecurityFingerprint,
  'security-lock': CFSecurityLock,
  'security-safe': CFSecuritySafe,
  'security-scraping': CFSecurityScraping,
  'security-shield-ddos-protection': CFSecurityDDOSProtection,
  'security-shield-protection': CFSecurityProtection,
  'security-shield-zero-trust': CFSecurityZeroTrust,
  'security-waf': CFSecurityWaf,
  'server-1': CFServerRows,
  'server-2': CFServerDouble,
  'server-3': CFServerStacked,
  'server-database': CFServerDatabase,
  'server-origin': CFServerOrigin,
  share: CFShare,
  'shop-cart': CFShopCart,
  'shop-cart-no': CFShopCartNo,
  'spam-email': CFSpamEmail,
  'support-chat': CFSupportChat,
  target: CFTarget,
  'target-retarget': CFTargetRetarget,
  time: CFTime,
  'traffic-attack': CFTrafficAttack,
  'traffic-legit': CFTrafficLegit,
  user: CFUser,
  'user-add-member': CFUserAddMember,
  'user-multi': CFUserMulti,
  'x-exit': CFXExit,
  'yes-check': CFYesCheck,
};
