import { getColorSubset } from '../Elements/types';

const brandColors = getColorSubset([
  'black',
  'white',
  'gray0',
  'gray1',
  'gray2',
  'gray3',
  'gray4',
  'orange0',
  'orange1',
  'orange2',
  'blue0',
  'blue1',
  'blue2',
  'blue3',
  'blue4',
  'blue5',
  'bluehover',
  'green0',
  'green1',
  'green2',
  'green3',
  'green4',
  'red0',
  'red1',
  'red2',
  'red3',
  'red4',
]);

export type BrandColors = typeof brandColors[number];

type ColorMap = {
  [x in BrandColors]: string;
};

export const colorMap: ColorMap = {
  black: '#222',
  white: '#FFF',
  gray0: '#4E4E4E',
  gray1: '#747474',
  gray2: '#B9B9B9',
  gray3: '#4E4E4E',
  gray4: '#F0F0F0',
  orange0: '#FF6633',
  orange1: '#F6821F',
  orange2: '#FBAD41',
  blue0: '#003682',
  blue1: '#0055DC',
  blue2: '#6ECCE5',
  blue3: '#C5EBF5',
  blue4: '#E2F5FA',
  blue5: '#F8FBFB',
  bluehover: '#0044C2',
  green0: '#12523D',
  green1: '#4E4E4E',
  green2: '#57CF7D',
  green3: '#BCECCB',
  green4: '#DDF5E5',
  red0: '#B0291C',
  red1: '#FC3D2E',
  red2: '#FCA39C',
  red3: '#FEDAD7',
  red4: '#FEEDEB',
};

export type Icons =
  | 'airplane-paper'
  | 'analytics-bots'
  | 'analytics-data'
  | 'analytics-network'
  | 'analytics-pie'
  | 'arrow'
  | 'arrow-backward'
  | 'arrow-external-link-1'
  | 'arrow-external-link-2'
  | 'arrow-twoway'
  | 'attacker'
  | 'attention'
  | 'benefits-healthcare'
  | 'benefits-paid-vacation'
  | 'benefits-parental-leave'
  | 'benefits-returnship'
  | 'benefits-salary'
  | 'browser-video'
  | 'calendar'
  | 'caret-down-1'
  | 'caret-left-1'
  | 'caret-left-2'
  | 'caret-reorder'
  | 'caret-right-1'
  | 'caret-right-2'
  | 'caret-up-1'
  | 'case-study'
  | 'cell-tower'
  | 'certificate'
  | 'certificate-manager'
  | 'check'
  | 'cloud-hybrid'
  | 'cloud-internet'
  | 'cloud-multi'
  | 'cloud-upload'
  | 'cloudflare-access'
  | 'cloudflare-api'
  | 'cloudflare-browser'
  | 'cloudflare-gateway'
  | 'cloudflare-pages'
  | 'cloudflare-radar'
  | 'cloudflare-registrar'
  | 'cloudflare-spectrum'
  | 'cloudflare-stream-delivery'
  | 'cloudflare-warp'
  | 'cloudflare-workers'
  | 'code-api'
  | 'code-content-optimization'
  | 'code-js'
  | 'collapse'
  | 'connect-1'
  | 'connect-2'
  | 'connect-3'
  | 'connect-iot'
  | 'copy-duplicate'
  | 'ddos-attack'
  | 'device-desktop'
  | 'device-laptop'
  | 'device-mobile'
  | 'device-tablet'
  | 'document-clipboard'
  | 'document-list'
  | 'documentation-logs'
  | 'documentation-rules'
  | 'download'
  | 'durable-objects'
  | 'ease-of-use'
  | 'edge-log-delivery'
  | 'edit'
  | 'email'
  | 'expand'
  | 'eyeball'
  | 'face-happy'
  | 'face-sad'
  | 'filtering'
  | 'geo-key-manager'
  | 'hamburger-1'
  | 'hamburger-2'
  | 'health-check'
  | 'help-giving'
  | 'help-question'
  | 'image'
  | 'industry'
  | 'industry-gaming'
  | 'info'
  | 'innovation-intelligence'
  | 'innovation-thinking'
  | 'internet-browser'
  | 'internet-globe'
  | 'ip-truncation'
  | 'key'
  | 'leader-crown'
  | 'learning-center-block'
  | 'link'
  | 'loading'
  | 'location-pin'
  | 'logo-facebook'
  | 'logo-github'
  | 'media-pause'
  | 'media-play'
  | 'media-stop'
  | 'more-1'
  | 'more-2'
  | 'network-scale'
  | 'network-virtual-backbone'
  | 'no-security-shield'
  | 'no-stop'
  | 'no-stop-x'
  | 'notification-announcements'
  | 'numeric-1'
  | 'numeric-2'
  | 'numeric-3'
  | 'numeric-add'
  | 'numeric-minus'
  | 'numeric-plus'
  | 'numeric-subtract'
  | 'office-branch'
  | 'office-headquaters'
  | 'open-door'
  | 'optimization-gear'
  | 'optimization-scale'
  | 'optimization-web'
  | 'orbit'
  | 'payments-credit-card'
  | 'performance-1'
  | 'performance-acceleration-bolt'
  | 'performance-acceleration-rocket'
  | 'performance-arrow-up'
  | 'performance-cloud-speed'
  | 'performance-intelligent-routing'
  | 'performance-routing'
  | 'performance-validator'
  | 'performance-wrench'
  | 'platform-apps'
  | 'power'
  | 'premium-success-offering'
  | 'price'
  | 'printer'
  | 'process-flow'
  | 'process-stack'
  | 'pull-request'
  | 'pull-request-merged'
  | 'refresh'
  | 'regional-services'
  | 'reliability-dns'
  | 'reliability-dns-resolver'
  | 'reliability-load-balancer'
  | 'reliability-timer'
  | 'revert'
  | 'router'
  | 'satelite'
  | 'search'
  | 'security-bots'
  | 'security-crawler'
  | 'security-fingerprint-privacy'
  | 'security-lock'
  | 'security-safe'
  | 'security-scraping'
  | 'security-shield-ddos-protection'
  | 'security-shield-protection'
  | 'security-shield-zero-trust'
  | 'security-waf'
  | 'server-1'
  | 'server-2'
  | 'server-3'
  | 'server-database'
  | 'server-origin'
  | 'share'
  | 'shop-cart'
  | 'shop-cart-no'
  | 'spam-email'
  | 'support-chat'
  | 'target'
  | 'target-retarget'
  | 'time'
  | 'traffic-attack'
  | 'traffic-legit'
  | 'user'
  | 'user-add-member'
  | 'user-multi'
  | 'x-exit'
  | 'yes-check';

export interface IconProps {
  size: number;
  color: BrandColors;
  className?: string;
}

export type IconMap = {
  [x in Icons]: React.ComponentType<IconProps>;
};
